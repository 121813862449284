import * as React from 'react';
import {Box, useTheme, ListItem, IconButton} from '@mui/material/';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import AddIcon from '@mui/icons-material/Add';

import { tokens, theme } from "../themes";

export default function SelectedListItem({selected, onClick,onClickSecondary, icon, primary,backgroundColor, secondary,fontColor} ) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ width: '100%',  bgcolor: 'background.paper' }}>
        <ListItem
            key={1}
            secondaryAction={
              <IconButton onClick = {onClickSecondary} edge="end" aria-label="comments">
                <AddIcon />
              </IconButton>
            }
            disablePadding
          >
        <ListItemButton
          selected={selected}
          sx={{backgroundColor: colors.primary[500], "&.Mui-selected": {
            backgroundColor: colors.blueAccent[800]},"&.Mui-hoover": {
                backgroundColor: colors.greenAccent[500]} }}
          onClick={onClick}
        >
         
          <ListItemText primaryTypographyProps={{fontSize: '15px', color:{fontColor}}} primary={ primary} secondary = {secondary} />
        </ListItemButton>
        </ListItem>
       
    </Box>
  );
}



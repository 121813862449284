

import React from 'react'
import "./PdfFile.css"
import { Box, Button, SpeedDialAction, CircularProgress, SpeedDialIcon, SpeedDial, useTheme } from "@mui/material"
import { useReportQuery } from "../../Hooks/useReportQuery"
import sellr from "./transparent.png"
import StoreSelect from "../../Components/StoreSelect"
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { ColorModeContext, tokens } from "../../themes"
import emailjs from "@emailjs/browser"
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useStore } from "../../Store/Zustand"
import { UserInfo } from "../../Hooks/useUserQuery"
import { useAuth0 } from "@auth0/auth0-react"


const api = axios.create({


    baseURL: "https://api.sellrconsultingsoftware.net"
})



const postEmail = async (submitToReceivedData) => {
    const response = await api.post("/send_mail",
        submitToReceivedData
    )
    return response
}

export default function PdfFile() {
    const { loginWithRedirect, isAuthenticated, logout, getAccessTokenSilently, isLoading, user } = useAuth0();

    const name = user.name
    const email = user.email
    const storeName = useStore(state => state.store)

   


    const userInfo = UserInfo()


    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const printRef = React.useRef()

    let months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    let today = new Date();
    let year = today.getFullYear();
    let month = months[today.getMonth()];
    let day = today.getDate();

    let formattedDate = `${month} ${day}, ${year}`;
    let formattedDateThree = `${month} ${day + 3}, ${year}`;

    let todayInZuluDate = today.toISOString()

    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const monthAgoISO = monthAgo.toISOString();

    // const generatePDF = () => {
    //     const report = new JsPDF('landscape', 'mm', 'a4');
    //     report.html(document.querySelector('#report')).then(() =>{
    //         report.save('report.pdf')
    //     })
    // }

    const generatePDF = async () => {
        let element = printRef.current;
        let canvas = await html2canvas(element);
        let data = canvas.toDataURL('image/png')
        let imgWidth = 210;
        let pageHeight = 295;
        let position = 0;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let pdf = new JsPDF();
        pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        pdf.save('file.pdf')


    }

    const sendEmail = async (e) => {

        let element = printRef.current;
        let canvas = await html2canvas(element);
        let data = canvas.toDataURL('image/png')
        let imgWidth = 210;
        let pageHeight = 295;
        let position = 0;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let pdf = new JsPDF();
        pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        let pdfBlob = pdf.output('datauristring', "BigDick.pdf")
        console.log(pdfBlob)


        const attachments = {
            attachments: [{ filename: storeName[0] +"_" + formattedDate, data: pdfBlob}],
            to: ['tjandrews1990@gmail.com']
        }

        sendEmailPost.mutate(attachments)
    }

    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Download PDF', onClick: generatePDF },
        { icon: <PrintIcon />, name: 'Download All' },
        { icon: <ShareIcon />, name: 'Share', onClick: sendEmail },
    ]



    const sendEmailPost = useMutation({
        mutationFn: postEmail

    })



    const reportQuery = useReportQuery(monthAgoISO, todayInZuluDate)

    if (reportQuery.isLoading) return console.log("Report is loading");
    if (reportQuery.isError) console.log(`ReportQuery() Error: ${reportQuery.error}`);

    const financeData = JSON.parse(reportQuery.data)

    if (userInfo.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
    if (userInfo.isError) return <pre>{JSON.stringify(userInfo.error)}</pre>;




    return (



        <Box m="20">
            <Box display="flex" ml="7vh" padding="5" justifyContent="space-between" alignItems="center">
                <StoreSelect />
                <Box sx={{ transform: 'translateZ(0px)', flexGrow: 0 }}>
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'flex', mr: 8, mb: 5, color: colors.greenAccent[500] }}
                        icon={<SpeedDialIcon />}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                            />
                        ))}
                    </SpeedDial>
                </Box>
                {/* <Button variant = "contained" sx={{backgroundColor:colors.greenAccent[500], color: colors.primary[900] }}onClick = {generatePDF}>Save to PDF</Button>
        <Button variant = "contained" sx={{backgroundColor:colors.greenAccent[500], color: colors.primary[900] }}onClick = {sendEmail}>Send Email</Button> */}
            </Box>
            <div id="report" ref={printRef} className="invoice-box">
                <table cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr className="top">
                            <img src={sellr} height="150px" />
                            <td colSpan="2">
                                <table>
                                    <tr>
                                        <td className="title">

                                        </td>

                                        <td>
                                            Invoice #: {Math.floor(Math.random() * 1000)}<br />
                                            Created: {formattedDate}<br />
                                            Due: {formattedDateThree}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr className="information">
                            <td colSpan="2">
                                <table>
                                    <tr >

                                        <td>
                                            Sellr Consulting<br />
                                            5345 Sunny Road<br />
                                            Sunnyville, CA 12345
                                        </td>

                                        <td>
                                            {storeName}<br />
                                            {name}<br />
                                            {email}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>


                        <tr className="heading">
                            <td>Total Owed:</td>

                            <td>$</td>
                        </tr>
                        <br/>
                        <tr className="details">
                            <td>Profit to Sellr</td>

                            <td>{"$ " + (financeData.totals.profit - parseFloat(financeData.totals.profit).toFixed(2) * .7).toFixed(2)}</td>
                        </tr>
                       <br/>
                       <br/>
                       

                        <tr className="heading">
                            <td>Item</td>

                            <td>$</td>
                        </tr>
                        <br/>
                        <tr className="item">
                            <td>Revenue </td>

                            <td>{"$ " + parseFloat(financeData.totals.revenue).toFixed(2)}</td>
                        </tr>
                        <br/>
                        <tr className="item">
                            <td> Total Profit  </td>

                            <td>{"$ " + parseFloat(financeData.totals.profit).toFixed(2)}</td>
                        </tr>
                        <br/>
                        <tr className="item">
                            <td>Profit Split % </td>

                            <td>% 30</td>
                        </tr>
                        <br/>
                        <tr className="item">
                            <td>Profit to Client </td>

                            <td>{"$ " + (parseFloat(financeData.totals.profit).toFixed(2) * .7).toFixed(2)}</td>
                        </tr>
                        <br/><br/>
                        <tr className="heading">
                            <td>Expenses</td>

                            <td>$</td>
                        </tr>
                        <br/>
                        <tr className="item">
                            <td>Product Cost</td>

                            <td>{"$ " + parseFloat(financeData.totals.product_cost).toFixed(2)}</td>
                        </tr>
                        <br/>
                        <tr className="item">
                            <td>Fees</td>

                            <td>{"$ " + parseFloat(financeData.totals.fees).toFixed(2)}</td>
                        </tr>

                        <br/>

                        <tr className="total expense">
                            <td>Total Expenses</td>

                            <td>{"$ " + parseFloat(financeData.totals.fees - financeData.totals.product_cost).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Box>


    )
}
import { useState, useRef} from "react"
import { Box, Button, Divider, styled, List, ListItem, InputAdornment, InputLabel, Tooltip, CircularProgress, IconButton, Backdrop, Card, Tab, Alert, Tabs, inputLabelClasses, Modal, inputClasses, CardHeader, Typography, CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid } from "@mui/material"
import Header from "../../Components/Header"
import { Formik, Form, Field, ErrorMessage, FieldArray, FastField } from "formik"

import { tokens, theme } from "../../themes";
import UnitListItem from "../../Components/UnitListItem"
import StoreSelect from "../../Components/StoreSelect";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AsinListItem from "../../Components/AsinListItem"
import { AsinSearch } from "../../Hooks/useAsinSearch"
import CheckIcon from '@mui/icons-material/Check';
import VendorCreationStepper from "../../Components/VendorForm"




export default function Assignment() {
    const [selectedIndexProduct, setSelectedIndexProduct] = useState(0);
    const [selectedIndexASIN, setSelectedIndexASIN] = useState(0);
    const [open, setOpen] = useState(false);
    const [ asin, setAsin] = useState(null)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [asinResponse, setAsinResponse] = useState(null)
    const [error, setError] = useState(false)

    const openModal = () => {
        setOpen(true)
    }
    const closeModal = () => {
        setOpen(false)
    }
   
    const asinSearch = AsinSearch("")
  

    const ref = useRef(null);

    if (asinSearch.isLoading) return console.log("Asin is loading");
    if (asinSearch.isError) setError(true)

    console.log(asinSearch.data)

    const TextFields = styled(TextField)(`
  .${inputClasses.root} {
  font-size: 10px;
  color: ${colors.greenAccent[500]}
  }
  .${inputLabelClasses.root} {
  font-size: 13px;
  font-weight: bold;
  color: ${colors.grey[100]};
  &.${inputLabelClasses.focused} {
      color: ${colors.grey[700]};
  }
 
  }
  `);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const initialAddAsinValues = {
        ASIN: "",
        Quantity: ""
    }
  
    const handleGetAsin = () => {
        const asin = ref.current.values.asin
        setAsin(asin)
        asinSearch.refetch(asin)
        console.log(asinResponse)
        setAsinResponse(asinSearch.data.data.catalog_data.AttributeSets[0].Title)        
    }
  

    const handleFormSubmit = () => {

    }
    const handleListItemClick = (event, index) => {
        setSelectedIndexProduct(index);
    };
    const handleASINItemClick = (event, index) => {
        setSelectedIndexASIN(index);
    };

    return (
        <Box m="20px">
            <Header title="ASSIGNMENT" subtitle="Assign Wholesale Products to Stores" />
           
            <Box display="flex" mb="20px">
                <StoreSelect />
                <Box display="flex" padding="20px" sx={{ borderRadius: "5%" }} backgroundColor={colors.primary[500]}>

                    {/* <Button variant="outlined" sx={{backgroundColor:colors.blueAccent[500]}}>
                        Add Available ASIN
                    </Button> */}
                    <Tooltip title="Add an Available ASIN">
                        <IconButton onClick={openModal} type="button" sx={{ p: 1, color: colors.blueAccent[500], p: 2 }}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Modal
                        backgroundColor={colors.primary[500]}
                        open={open}
                        onClose={closeModal}
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Box sx={modalStyle} >
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialAddAsinValues}
                                enableReinitialize
                                innerRef={ref}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                            sx={{
                                                "& > div": { gridColumn: "span 4" },
                                            }}>

                                            <Box display="flex" justifyContent="space-between">
                                                {asinResponse && <><Typography sx={{width:"60%"}}>{asinResponse}</Typography> <CheckIcon sx={{color:"green" }}/> </>}
                                                {error && <><Typography sx={{width:"60%"}}>"Bad Asin"</Typography> <CheckIcon sx={{color:"Red" }}/> </>}
                                                <Button onClick={handleGetAsin} sx={{ backgroundColor: colors.greenAccent[500] }}>Verify ASIN</Button>
                                            </Box>
                                            <InputLabel>ASIN</InputLabel>
                                            <TextFields
                                                fullWidth
                                                variant="outlined"

                                                type="text"
                                                label="ASIN"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{
                                                    startAdornment: "$"
                                                }}
                                                value={values.asin}
                                                name="asin"
                                                error={!!touched.asin && !!errors.asin}
                                                helperText={touched.asin && errors.asin}
                                                sx={{
                                                    input: { color: colors.grey[100] },
                                                    gridColumn: "span 2",
                                                    backgroundColor: colors.primary[500]
                                                }}
                                            />
                                            <InputLabel>Quantity</InputLabel>
                                            <TextFields />
                                            <Box>
                                                <Button sx={{ backgroundColor: colors.greenAccent[500] }}>Submit</Button>
                                            </Box>
                                        </Box>

                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Modal>
                    <Tooltip title="Delete an Available ASIN">
                        <IconButton type="button" sx={{ p: 1, color: colors.blueAccent[500], p: 2 }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit ASIN">
                        <IconButton type="button" sx={{ p: 1, color: colors.blueAccent[500], p: 2 }}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between" gap="10px" >
                <List subheader="Products to Assign" sx={{ width: "20%" }} >
                    <UnitListItem
                        primary="PaperTape "
                        secondary="12344321"
                        selected={selectedIndexProduct === 0}
                        onClick={(event) => handleListItemClick(event, 0)}

                    />

                    <UnitListItem
                        primary="This Product Name Here 3"
                        secondary="12344321"
                        selected={selectedIndexProduct === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    />
                </List>
                <List subheader="Available ASINS" sx={{ width: "30%" }} >
                    <AsinListItem
                        primary="ASIN 12345 "
                        secondary="Units Required: 2"
                        selected={selectedIndexASIN === 0}
                        onClick={(event) => handleASINItemClick(event, 0)}
                        onClickSecondary={openModal}
                    />

                    <AsinListItem
                        primary="ASIN 123456"
                        secondary="United Required: 3"
                        selected={selectedIndexASIN === 1}
                        onClick={(event) => handleASINItemClick(event, 1)}
                    />
                    <AsinListItem
                        primary="ASIN 4325234"
                        secondary="United Required: 5"
                        selected={selectedIndexASIN === 2}
                        onClick={(event) => handleASINItemClick(event, 2)}
                    />
                </List>
                <Divider />
                <Box width="50%" height="70vh" backgroundColor={colors.primary[600]}>
                    <List subheader="Assigned Products" sx={{ width: "97%", margin: "10px 10px 10px 10px" }} >
                        <UnitListItem
                            primary="PaperTape "
                            secondary="12344321"
                            onClick={(event) => handleListItemClick(event, 0)}
                        />
                        <UnitListItem
                            primary="This Product Name Here 3"
                            secondary="12344321"
                            onClick={(event) => handleListItemClick(event, 1)}
                        />
                    </List>
                    <Box display="flex" justifyContent="end" mr="20px">
                        <Button display="flex" variant="outlined" sx={{ backgroundColor: colors.greenAccent[500] }} > Assign</Button>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}
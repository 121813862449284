import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"







export const PackingData =  (onSuccess, onError) => {

    const api = axios.create({
        baseURL: "https://api.sellrconsultingsoftware.net"
      })
      const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();

      const getPackingData = async () => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
            },
        })

        const response = await api.get("/packing",{
            headers:{ 
              'Content-Type': 'application/json',
          'authorization': `Bearer ${accessToken}` }
          })
          
        return response.data
    }


    return useQuery({
    queryKey: ["packing"],
    queryFn: () => getPackingData()


})}

import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"


   
    const api = axios.create({
        baseURL: "https://api.sellrconsultingsoftware.net"
    })

    const getReceiving = async() => {
        const response = await api.get("/receiving?")
        
        return response.data
    }

    
    export const GetReceiving = (onSuccess, onError) =>{ 
        const api = axios.create({
            baseURL: "https://api.sellrconsultingsoftware.net"
          })
        const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();

        const getReceiving = async() => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                },
            })

            const response = await api.get("/receiving?", {
                headers:{ 
                  'Content-Type': 'application/json',
              'authorization': `Bearer ${accessToken}` }
              })
            
            return response.data
        }
        return useQuery({
        queryKey: ["receiving"],
        queryFn: () => getReceiving(), 
    })}
    

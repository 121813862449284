import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"

    
    export const useReportQuery = (startDate,endDate) =>{ 
        const api = axios.create({
            baseURL: "https://api.sellrconsultingsoftware.net"
          })
          const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();
        
          const getReport = async() =>{ 
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                },
            })

            const response = await api.get("/report?", {
                headers:{ 
                    'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`},
                params: {
                start_date: startDate,
                end_date: endDate,
                store_id: 'Worth Services LLC',
                test: 'test"'
                },
               
            })
            const responseData = response.data
            return responseData
        }
    


        return useQuery({
        queryKey: ["report"],
        queryFn: () => getReport(), 
    })}
    

import { Box, Button, DialogTitle, Dialog, Select, DialogContent, MenuItem, DialogActions, DialogContentText, Snackbar, Autocomplete, Input, IconButton, InputLabel, ListItem, ListItemText, Modal, Backdrop, LinearProgress, Alert, List, Tooltip, Divider, Typography, TextField, useTheme } from "@mui/material"
import { Formik } from "formik"
import { useState, useRef, Fragment, useCallback } from "react"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../Components/Header"
import { tokens, theme } from "../../themes";
import { GetVendorQuery } from "../../Hooks/useVendorQuery"
import axios from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import UnitListItem from "../../Components/UnitListItem"
import AWS from 'aws-sdk';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddSkuForm from "../../Components/AddSkuForm"
import { WarehouseSkuQuery } from "../../Hooks/useWarehouseSkusQuery"
import VendorForm from "../../Components/VendorForm"
import { useDropzone } from 'react-dropzone'
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ConfirmDialogue from "../../Components/ConfirmDialogue"
import SuccessAlert from "../../Components/SuccessAlert"
import { WarehousePOs } from "../../Hooks/useWarehousePoQuery"


const initialValues = {


}

const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    minWidth: "60%",
    height: 'auto',
    maxHeight: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "scroll"
};


export default function BillOfLading() {

    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 15,
            bottom: params.isLastVisible ? 0 : 15,
        }
    });

    const WarehousePOQuery = WarehousePOs()

    if (WarehousePOQuery.isLoading) return console.log('waiting')
    if (WarehousePOQuery.isError) return console.log('failed')

    let poData = WarehousePOQuery.data.data

    console.log(poData)


    const columnsPurchaseOrders = [
        {
            field: "purchase_order_uuid",
            headerName: "UUID",
            minWidth: 150,
            align: "center",
        },
        {
            field: "purchase_order_number",
            headerName: "Order #",
            minWidth: 150,
            align: "center",
        },
        {
            field: "vendor_name",
            headerName: "Vendor",
            minWidth: 150,
            align: "center",
        },
        // {
        //     field: "Order",
        //     headerName: "Order Status",
        //     minWidth: 150,
        //     align: "center",
        // },
        {
            field: "purchase_order_date",
            headerName: "Created Date",
            minWidth: 150,
            align: "center",
        },
        // {
        //     field: "Order",
        //     headerName: "Closed Date",
        //     minWidth: 150,
        //     align: "center",
        // },
        {
            field: "expected_delivery",
            headerName: "Expected Delivery",
            minWidth: 150,
            align: "center",
        },
        // {
        //     field: "Order",
        //     headerName: "Inventory Status",
        //     minWidth: 150,
        //     align: "center",
        // },
        {
            field: "Order",
            headerName: "Received/Ordered",
            valueGetter: (params) => params.row.products[0].quantity,
            minWidth: 150,
            align: "center",
        },
        {
            field: "grand_total",
            headerName: "Total Cost",
            valueFormatter: (params) => "$" + parseFloat(params.value).toFixed(2),
            minWidth: 150,
            align: "center",
        },
    ]

    return (
        <Box m="20px">
            <Header title="Purchase Orders" subtitle="Come Here To Receive Purchase Orders" />

            <Box
                        m="40px 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: ".1",
                                borderTop: ".1"
                            },


                            "& .name-column--cell": {
                                color: colors.greenAccent[300]
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.primary[500],
                                borderBottom: "none",
                                textDecoration: "underline"
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[500],
                                fontSize: "20px"

                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.primary[500],
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`
                            },


                        }}
                    >

                        
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'PurchaseDate', sort: 'asc' }],
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns id and lastname.
                                        // Other columns will remain visible

                                        purchase_order_uuid: false,


                                    }
                                },

                            }}

                            showCellRightBorder={true}
                            getRowHeight={() => 'auto'}
                            disableDensitySelector
                            rows={poData}
                            getRowSpacing={getRowSpacing}
                            getRowId={(row) => row.purchase_order_uuid}
                            columns={columnsPurchaseOrders}
                            components={{ Toolbar: GridToolbar }}

                            GridLinesVisibility="None"
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important"
                                },
                                '& .Mui-selected': {
                                    backgroundColor: `${colors.greenAccent[800]} !important`
                                }
                                ,
                            }}


                        />
                        </Box>
        </Box>
    )
}
import { useTheme } from "@mui/material"
import {ResponsiveBar} from "@nivo/bar"
import {tokens} from "../themes"
import {mockFinancials as data} from "../data/mockData"
import { getDefaultReport} from "../Globals/api";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query"
import {DefaultReportQuery} from "../Hooks/useDefaultReportQuery"




export default function BarChart({isDashboard = false, metricValue, dateValue}){
    const theme=useTheme();
    const colors=tokens(theme.palette.mode)



  
          const reportQuery = DefaultReportQuery()
          if(reportQuery.isLoading) return <h1> Loading...</h1>
          if(reportQuery.isError) return <pre>{JSON.stringify(reportQuery.error)}</pre>
          const Data = JSON.parse(reportQuery.data)
        
         
          
          
//         //   console.log(financeData)
       
       function sortByDay(dataArray) {
        return dataArray.slice().sort((a, b) => {
          const dayA = parseInt(a.day);
          const dayB = parseInt(b.day);
          return dayA - dayB;
        });
      }

      function sortByMonth(dataArray) {
        const monthsOrder = {
          January: 1,
          February: 2,
          March: 3,
          April: 4,
          May: 5,
          June: 6,
          July: 7,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12
        };
      
        return dataArray.slice().sort((a, b) => {
          const monthA = monthsOrder[a.month];
          const monthB = monthsOrder[b.month];
          return monthA - monthB;
        });
      }

      function sortByWeek(dataArray) {
        return dataArray.slice().sort((a, b) => {
          const weekA = parseInt(a.week);
          const weekB = parseInt(b.week);
          return weekA - weekB;
        });
      }
  
           const tableData = Data.table_data
         
      
    
       
        const formattedData = tableData?.map(item => {
         const purchaseDate = new Date(item.order_created);
       
         // Parse revenue, product_cost, and fees to numbers
         const revenue = parseFloat(item.revenue) || 0;
         const productCost = parseFloat(item.product_cost) || 0;
         const fees = parseFloat(item.fees) || 0;
       
         // Calculate profit
         const profit = revenue - productCost - fees;
       
         return {
           day: purchaseDate.getDate(),
           month: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(purchaseDate),
           year: purchaseDate.getFullYear(),
           revenue: revenue,
           product_cost: productCost,
           fees: fees,
           profit: profit
         };
       });
       
    //    console.log(formattedData);


       const totalsByDay = {};
       const totalsByWeek = {};
       const totalsByMonth = {};
       const totalsByYear = {};
       
       formattedData?.forEach(item => {
         const { day, month, year, revenue, profit, fees, product_cost } = item;
       
         // Totals by day
         if (!totalsByDay[`${year}-${month}-${day}`]) {
           totalsByDay[`${year}-${month}-${day}`] = { day, month, year, totalRevenue: 0, totalProfit: 0, totalFees: 0, totalProductCost: 0 };
         }
         totalsByDay[`${year}-${month}-${day}`].totalRevenue += revenue;
         totalsByDay[`${year}-${month}-${day}`].totalProfit += profit;
         totalsByDay[`${year}-${month}-${day}`].totalFees += fees;
         totalsByDay[`${year}-${month}-${day}`].totalProductCost += product_cost;
       
         // Totals by week - assuming each week starts on Monday
         const weekNumber = Math.ceil(day / 7);
         const weekKey = `${year}-W${weekNumber}`;
         if (!totalsByWeek[weekKey]) {
           totalsByWeek[weekKey] = { week: weekNumber, year, totalRevenue: 0, totalProfit: 0, totalFees: 0, totalProductCost: 0 };
         }
         totalsByWeek[weekKey].totalRevenue += revenue;
         totalsByWeek[weekKey].totalProfit += profit;
         totalsByWeek[weekKey].totalFees += fees;
         totalsByWeek[weekKey].totalProductCost += product_cost;
       
         // Totals by month
         const monthKey = `${year}-${month}`;
         if (!totalsByMonth[monthKey]) {
           totalsByMonth[monthKey] = { month, year, totalRevenue: 0, totalProfit: 0, totalFees: 0, totalProductCost: 0 };
         }
         totalsByMonth[monthKey].totalRevenue += revenue;
         totalsByMonth[monthKey].totalProfit += profit;
         totalsByMonth[monthKey].totalFees += fees;
         totalsByMonth[monthKey].totalProductCost += product_cost;
       
         // Totals by year
         if (!totalsByYear[year]) {
           totalsByYear[year] = { year, totalRevenue: 0, totalProfit: 0, totalFees: 0, totalProductCost: 0 };
         }
         totalsByYear[year].totalRevenue += revenue;
         totalsByYear[year].totalProfit += profit;
         totalsByYear[year].totalFees += fees;
         totalsByYear[year].totalProductCost += product_cost;
       });

// Convert totals objects to arrays
const totalsByDayArray = Object.values(totalsByDay);
const totalsByWeekArray = Object.values(totalsByWeek);
const totalsByMonthArray = Object.values(totalsByMonth);
const totalsByYearArray = Object.values(totalsByYear);



  const totalRevenue = totalsByDayArray?.map(item => ({
    day: item.day,
    month: item.month,
    week: item.week,
    year: item.year,
    revenue: item.totalRevenue
  }));



  const totalProfit = sortByDay(totalsByDayArray?.map(item => ({
    day: item.day,
    month: item.month,
    week: item.week,
    year: item.year,
    profit: item.totalProfit
  })));

  const totalFees = sortByDay(totalsByDayArray?.map(item => ({
    day: item.day,
    month: item.month,
    week: item.week,
    year: item.year,
    fees: item.totalFees
  })));

  const totalProductCost = sortByDay(totalsByDayArray?.map(item => ({
    day: item.day,
    week: item.week,
    year: item.year,
    month: item.month,
    product_cost: item.totalProductCost
  })));

console.log()


return (

    <ResponsiveBar
    data={(metricValue === "profit" ? totalProfit : 
        (metricValue ==="revenue" ? totalRevenue :
        (metricValue ==="fees" ? totalFees : (
            metricValue ==="product_cost" ? totalProductCost : totalProfit))))}
    theme={{
        axis: {
            domain: {
                line: {
                    stroke: colors.grey[100]
                     }
            },
            legend: {
                text: {
                    fill: colors.grey[100]
                }
            },
            ticks:{
                line:{
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                },
                text: {
                    fill: colors.grey[100]
                }
            }
        },
        legends:{
            text: {
                fill: colors.grey[100]
            }
        }
    }}
    keys={[
        metricValue ? metricValue: "profit"
        // "revenue"
    ]}
    indexBy={dateValue ? dateValue : "day"}
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors= {isDashboard? colors.greenAccent[600] :   colors.greenAccent[600]}
    defs={[
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ]}
    fill={[
        {
            match: {
                id: 'fries'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'sandwich'
            },
            id: 'lines'
        }
    ]}
    borderColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                1.6
            ]
        ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard? undefined: dateValue,
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0
    }}
    axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard? undefined: `${metricValue} $`,
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0
    }}
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                1.6
            ]
        ]
    }}
    legends={[
        {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1
                    }
                }
            ]
        }
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
/>
)
}





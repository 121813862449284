import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App"
import {BrowserRouter} from "react-router-dom"
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {QueryClient, queryClient, QueryClientProvider} from "@tanstack/react-query"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


const queryclient =  new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
<Auth0Provider
    domain="dev-k0oycjolh26a2qqo.us.auth0.com"
    clientId="CVYxPoKlymMfUfeojPTUaM39fqgI733v"
    authorizationParams={{
      redirect_uri: window.location.origin,
      issuer: "https://dev-k0oycjolh26a2qqo.us.auth0.com",
      audience: "api.sellrconsultingsoftware.net/api",
      scope: "read:roles update:current_user_metadata user-read-email openid profile email update:users read:resource_servers read:current_user read:user_idp_tokens "
    }}
    // scope= "read:users read:roles update:current_user_metadata user-read-email openid profile email update:users read:clients read:resource_servers read:current_user read:user_idp_tokens "
  >
    <QueryClientProvider client={queryclient}>

    <BrowserRouter>
    <App />
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
 </Auth0Provider>
);

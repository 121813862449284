
import {Dialog, DialogTitle, DialogContent, DialogContentText,DialogActions,Button} from "@mui/material"



export default function ConfirmDialogue({open,onClose,title,body, onClick,buttonText }){
    return(
        <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={onClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        
                        <Button color="secondary" variant="outlined" onClick={onClick} autoFocus>
                        {buttonText}
                        </Button>
                    </DialogActions>
                </Dialog>
    )
}
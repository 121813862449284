import {useState} from 'react';
import {Box, Button,styled, Card, Tab, Alert, Tabs, inputLabelClasses, inputClasses, CardHeader, Typography,CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid, colors} from "@mui/material"
import { Formik, Form, Field, ErrorMessage, FieldArray, FastField} from "formik"

export default function ItemUnitsInfo() {
    
    return(
    <Box>
        <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
            <CardHeader align ="center" title="Terminology" />
            <CardContent  alignContent="center" sx={{alignContent:"center"}} >
            Unit:  Units are components of orders. an individual item that is single and complete, and can be shipped on its own. 
            Note: If there are two items but they come packaged from the manufacteror together this is stil one unit. 
             Order from Amazon. This can be a single unit or multiple units. i.e One order from Amazon may ask for a pack of 3 individual units. 
            Purchased Item: Items bought from Source. This can also be a single unit or multiple units. The purchase source may be selling 3 indivudal units per Purchased Item.

                    <Typography align= "center" alignContent="center" color={colors.grey[100]} variant="h5">
                    <u>Item Quantity Bought</u>:The actual number of items purchased from our source. (Walmart is selling Colgate that comes in packs of 3. We buy a quantity of 10. The total units would be 30, but you would put 10 in the Item Quantity Bought)
                    </Typography>
               
                    <Typography align= "center" alignContent="center" color={colors.grey[100]} variant="h5">
                   <u> Units per Quantity Bought</u>: The number of units per order bought from the source. (If from Walmart we buy 1 orders of Colgate toothpaste that comes in a pack of 3 then the Units per Quantity Bought = 3)
                    </Typography>

                    <Typography align= "center" alignContent="center" color={colors.grey[100]} variant="h5">
                   <u>Units Per ASIN</u>: The number of individual units that will be listed on Amazon. (If the Amazon listing calls for a 4 pack of Colgate toothpaste then Units per ASIN = 4)
                    </Typography>


            </CardContent>
        </Card> 
    </Box>
    )
}
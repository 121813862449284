
import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu, SubMenuProps } from "react-pro-sidebar"
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom";
import { tokens } from "../themes";
import { useAuth0 } from "@auth0/auth0-react"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined"
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined"
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined"
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined"
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PaidIcon from '@mui/icons-material/Paid';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import FactoryIcon from '@mui/icons-material/Factory';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';



const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}>
            <Typography>
                {title}
            </Typography>
            <Link to={to} />
        </MenuItem>
    )
}



export default function Sidebar() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard")
    const [admin, setAdmin] = useState("")

    const { loginWithRedirect, isAuthenticated, logout, isLoading, user } = useAuth0();






    return (
        <Box
            sx={{
                "& .pro-siderbar-inner": {
                    background: `${colors.greenAccent[900]} !important`
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important"
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important"
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important"
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important"
                }

            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* Logo and Menu Icon */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}>
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>


                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    { /* USER */}
                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={isAuthenticated && !isLoading ? user?.picture : "https://www.cleanpng.com/png-computer-icons-letter-case-sans-serif-letter-s-928904/"}
                                    style={{ cursor: "pointer", borderRadius: "50%" }} />
                            </Box>
                            <Box textAlign="center">
                                {isAuthenticated && <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>{user.name}</Typography>}
                                <Typography variant="h5" color={colors.greenAccent[500]} >

                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {/* Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/Dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />


                      
                        <SubMenu title="Two-Step Dropshipping">
                            <Item
                                title="Pending Orders"
                                to="/IncomingOrders"
                                icon={<CallReceivedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Two-Step Receiving"
                                to="/Receiving"
                                icon={<WarehouseIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                        <SubMenu title="FBA Arbitrage">
                            <Item
                                title="Purchasing"
                                to="/Purchasing"
                                icon={<ShoppingCartIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                        <SubMenu title="Wholesale">
                            <SubMenu

                                title="Purchase Orders"
                            >
                                <Item
                                    title="Purchase Orders"
                                    to="/PurchaseOrders"
                                    icon={<EventNoteIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Create PO"
                                    to="/WholesalePurchasing"
                                    icon={<PostAddIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                
                            </SubMenu>
                            <SubMenu title="Vendors">

                                <Item
                                    title="Add Vendor"
                                    to="/Vendors"
                                    icon={<AddBusinessIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                            </SubMenu>
                            <SubMenu title="Inventory" icon={<LocalConvenienceStoreIcon />}>
                            <Item
                                title="Inventory"
                                to="/Inventory"
                                
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Wholesale Receiving"
                                to="/WholesaleReceiving"
                                
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                    title="Wholesale Assignment"
                                    to="/Assignment"
                                  
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </SubMenu>
                            

                        </SubMenu>
                        <SubMenu title="Financials">
                            <Item
                                title="Financial Overview"
                                to="/Financials"
                                icon={<PaidIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Create Invoices"
                                to="/Invoices"
                                icon={<RequestQuoteIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                            title="Bar Chart"
                            to="/Bar"
                            icon={<BarChartOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        </SubMenu>


                        <Item
                            title="Calendar"
                            to="/Calendar"
                            icon={<CalendarTodayOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {/* <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}>
                            Data
                        </Typography>




                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}> Charts
                        </Typography>

                        
                        <Item
                            title="Pie Chart"
                            to="/Pie"
                            icon={<PieChartOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Line Chart"
                            to="/Line"
                            icon={<TimelineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Geography Chart"
                            to="/Geography"
                            icon={<MapOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        /> */}
                    </Box>
                </Menu>
            </ProSidebar>

        </Box>
    )
}
import {Box,CircularProgress, List, Divider, Button,styled, FormHelperText,InputLabel,  IconButton, Backdrop, Card, Tab, Alert, Tabs, inputLabelClasses, Modal, inputClasses, CardHeader, Typography,CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid} from "@mui/material"
import React, { useEffect } from 'react'
import axios from "axios";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../themes";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StatBox from "../../Components/StatBox";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import moment from 'moment';
import { DateRangePicker,CustomProvider } from 'rsuite';
import BarChart from "../../Components/BarChart";
import LineChart from "../../Components/LineChart";
import { useQuery } from "@tanstack/react-query"
import {DefaultReportQuery} from "../../Hooks/useDefaultReportQuery"
import {StoresQuery} from "../../Hooks/useStoresQuery"
import { IncomingOrders } from "../../Hooks/useIncomingQuery";
import NewClientList from "../../Components/NewClientList";
import image from "./images/sample1.png"




function DataGridTitle() {
    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
    return(
        <Box height = "50px"  backgroundColor={colors.primary[600]} style={{width: "100%", display: "flex", justifyContent: "center"    , color:colors.primary[100], alignItems: "center"}}  >
            <Typography variant="h5"><b>Orders</b></Typography>
        </Box>
    )
}


const Dashboard =(props) =>{

    const navigate = useNavigate();

    const [date, setDate] = React.useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }) //state for calendar
    const [isCollapsed, setIsCollapsed] = React.useState(true) //probably unnecessary
    const [storeName, setStoreName] = React.useState([])

    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
  
   
            
            const incomingOrdersQuery = IncomingOrders()
            const defaultReportQuery= DefaultReportQuery()
            const storesQuery = StoresQuery()
                
            if(incomingOrdersQuery.isLoading ) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
            if(incomingOrdersQuery.isError) return <pre>{JSON.stringify(incomingOrdersQuery.error)}</pre>;
            if(defaultReportQuery.isLoading ) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
            if(storesQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
            if(storesQuery.isError) return <pre>{JSON.stringify(storesQuery.error)}</pre>

          
                
            const incomingOrdersData = JSON.parse(incomingOrdersQuery.data)
           
            
            const storesDataNoKey = JSON.parse(storesQuery.data)
            const reportData = JSON.parse(defaultReportQuery.data)
           

            let responseHandle = (data) => {
                let index = 0;
                for (let key in data) {
                    data[key].id = index++;
                }
                
                return data
            }
            
            const storesData = responseHandle(storesDataNoKey)
            const storeNames = storesData.map((x) => x.store_id)
          

          

    //columns

    

    const columns = [
        {
            field: "line_uuid", 
            headerName:"ID", 
            flex: 0.5},
        {
            field: "store_id", 
            headerName: "Store ID:",
            flex: 1,
            headerAlign: "center",
            align: "center",},
            
            {
            field: "PurchaseDate", 
            headerName: "Order Received:",
            valueGetter: ( incomingOrdersData ) => incomingOrdersData.row.order_data.PurchaseDate,
            valueFormatter: (PurchaseDate) => moment(PurchaseDate.value).format("MM-DD @ HH:mm"),
            flex: 1,
            headerAlign: "center",
            align: "center"
        },
            {
            field: "ASIN",
            headerName: "ASIN:",
            valueGetter: ( incomingOrdersData ) => incomingOrdersData.row.line_data.ASIN,
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "name-column--cell"}
            ,
    ]
    //Calendar Tools
    const handleClick = () =>{
        setIsCollapsed(!isCollapsed)
    }
    
    const handleDateChange = (ranges) =>{
        setDate(ranges.selection)
}
 //Store Select Tools
 const MenuProps = {
    PaperProps: {
      style: {
        width: 200,
        maxHeight: 200,
        bgcolor: colors.blueAccent[700]
        
      },
    },
  };


    function getStyles(storeNames, storeName, theme) {
  return {
    fontWeight:
      storeName.indexOf(storeNames) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};


   
   const handleStoreChange = (event) => {
    const {
      target: { value },
    } = event;   
    setStoreName(
      typeof value === 'string' ? value.split(',') : value,
      
    )
   
   }


    return(
        <Box m="20px">
            
            <Box display = "flex" justifyContent = "space-between" alignItems = "center">
            <Header title="DASHBOARD" subtitle="Overview of Activity" />
                <Box>   
                {/* <CustomProvider theme="dark">
                <DateRangePicker size = "lg" value = {date.startDate} format="MM-dd-yyyy" character=" : "  theme="dark" appearance="default" placeholder="Default" style={{ width: 230 }} />
            </CustomProvider> */}
            <FormControl sx={{ m: 1, width: 300  }}>
                           {storeNames? <InputLabel id="storeSelect">Store</InputLabel> : ""}
                        <Select
                            labelId="storeSelect"
                            id="storeSelects"
                            displayEmpty
                            label= {storeNames ? "Store" : ""}
                            value={storeNames}
                            // defaultValue = {{value: storeNames[0], label:storeNames[0]}}
                            onChange={handleStoreChange}
                
                            input={<OutlinedInput label="Store Name" />}
                            MenuProps={MenuProps}
                            sx ={{
                                backgroundColor: colors.primary[500],
                                height: 60,
                                autoWidth: "true",
                
                            }}
                            >
                            {storeNames.map((name) => (
                                <MenuItem
                                key={name}
                                
                                value={name}
                                style={getStyles(storeNames, storeName, theme)}
                                >
                                {name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{marginLeft: 3,}}>Select Store</FormHelperText>
                        </FormControl>
                    </Box>
                    </Box>
                    <Box
                    display = "grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap='20px'
                    >
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
                    <StatBox 
                    title = {incomingOrdersData.length}
                    subtitle = "Orders To Ship"
                    progress = "0.75"
                    increase = "14%"
                    icon = {<LocalShippingIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
                    <StatBox 
                    title = {"$ " +parseFloat(reportData.totals.revenue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    subtitle = "Total Revenue"
                    progress = "0.75"
                    increase = "+23%"
                    icon = {<CallReceivedIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/> }
                    />
                    </Box> 
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
                    <StatBox 
                    title = {"$ " +parseFloat(reportData.totals.profit).toFixed(2)}
                    subtitle = "Profit"
                    progress = "0.75"
                    increase = "+29%"
                    icon = {<AttachMoneyIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
                    <StatBox 
                    title = {parseFloat(reportData.totals.roi).toFixed(2)}
                    subtitle = "ROI"
                    progress = {.75}
                    increase = "+23%"
                    icon = {<PercentIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
                    
                    <Box 
                         mt="-0px"
                         height = "45vh" 
                         gridColumn="span 3"
                         sx={{
                            "& .MuiDataGrid-root" : {
                                 border: "none"
                            },
                            "& .MuiDataGrid-cell" : {
                                borderBottom: "none !IMPORTANT",
                                borderTop: ".1"
                            },
                        
                        
                            "& .name-column--cell": {
                                color: colors.greenAccent[300]
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.primary[600],
                                color: colors.greenAccent[500],
                                borderBottom: "none"
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[500],
                            
                            },
                            "& .MuiDataGrid-footerContainer" :{
                                borderTop: "none",
                                backgroundColor: colors.primary[600],
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`
                            },


                        }} 
                    >
                        
            <DataGrid 
                 initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    columns: {
                     columnVisibilityModel: {
                       // Hide columns id and lastname.
                       // Other columns will remain visible
                       line_uuid: false,
                        
                     }                
                     },
                     sorting: {
                        sortModel: [{ field: 'PurchaseDate', sort: 'asc' }],
                      },
                }}
                
                showCellRightBorder={true}
                hideFooter = {true}
                rows = {incomingOrdersData}
                // getRowSpacing={getRowSpacing}
                getRowId={(row) => row.line_uuid}
                rowHeight={50}
                slots={{
                    toolbar: DataGridTitle
                  }}
                columns = {columns}
                GridLinesVisibility="None"
                sx= {{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important"
                    },
                    '& .Mui-selected':{
                        backgroundColor: `${colors.greenAccent[800]} !important`
                    }
                    ,
                }}
                
                
                />
               

                </Box>
                
                    

                <Box
                gridColumn = "span 6"
                height = "45vh" 
              
                >
                    <BarChart
                    isDashboard= {true} />
                    </Box>
                    
                    <Box
                    gridColumn = "span 3"
                    height = "45vh"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    
                    alignItems = "center"
                    >
                        <List>
                            <Typography variant="h3"sx={{padding:"15px"}} color={colors.greenAccent[500]}> New Clients to Onboard</Typography>
                <NewClientList primary = "John Walsh" secondary = "(319)219-4431 | John.Walsh@gmail.com"  />
                <NewClientList primary = "Terry Goodwin" secondary = "(416)919-4211 | Terru.Goodwin@gmail.com" />
                <NewClientList primary = "Patrick Adam" secondary = "(719)519-3531 | Patrick.Adam@gmail.com" />
                <NewClientList primary = "Larry Allan" secondary = "(720)219-4431 | Larry.Allan@gmail.com" />


                </List>
                </Box>
                </Box>
                
                    
                   
               
            </Box>)

}

export default Dashboard
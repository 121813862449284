import { Box, Button, List, Divider, Typography, TextField, useTheme } from "@mui/material"
import { useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../Components/Header"
import { tokens, theme } from "../../themes";
import { GetVendorQuery } from "../../Hooks/useVendorQuery"
import axios from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import UnitListItem from "../../Components/UnitListItem"
import VendorForm from "../../Components/VendorForm"
import ConfirmDialogue from "../../Components/ConfirmDialogue"


const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})

const deleteVendor = async (submitToPackedData) => {
    const response = await api.delete(`/vendors`, {data: {"vendor_id" : submitToPackedData}}
    
        
    )
    return response
}

const VendorPage = () => {

    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width: 600px)")
    const [selectedIndexVendor, setSelectedIndexVendor] = useState(0);
    const [open, setOpen] = useState({
        deleteSelectedVendor: false,
    })



    const vendorQuery = GetVendorQuery()

    const deleteSelectedVendor = useMutation({
        mutationFn: deleteVendor,
        onSuccess: data => {
            queryClient.invalidateQueries(["vendors"])
        }
    })


    if (vendorQuery.isLoading) return console.log('waiting')
    if (vendorQuery.isError) return console.log('failed')

    const vendorData = vendorQuery.data.data

    const handleListItemClick = (event, index) => {
        setSelectedIndexVendor(index);

    };

    let vendorList = vendorData?.map(function (vendor, i) {
        if (vendorData === undefined) {
            return "No Vendors. Please Add Vendor."
        }
        return <><UnitListItem 
        primary={"- " + vendor.vendor_name} 
        secondary={vendor.vendor_information.contact} 
        onClick={(event) => handleListItemClick(event, i)} 
        selected={selectedIndexVendor === i}  /> <Divider variant="fullWidth" /> </>;
    })

    const currentVendor = !vendorData[selectedIndexVendor] ? null: vendorData[selectedIndexVendor].vendor_name
    const handleOpen = (event) => {
        if (event === "deleteVendorConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, deleteVendorConfirmation: true
            }))
        }
    }

    const handleDeleteVendor = () =>{
        if (vendorData[selectedIndexVendor] === undefined ){
            console.log("fuck")
        }
        else{
        deleteSelectedVendor.mutate(vendorData[selectedIndexVendor].vendor_id)
        setOpen(prevOpen => ({
            ...prevOpen, deleteVendorConfirmation: false
        }))
        }
    }

    return (
        <Box m="20px">
            <Header title="Create Vendor" subtitle="Add a New Vendor" />
            <Box display="flex" padding="20px" justifyContent="space-between" >
          

                <Box mr="20px">
                    <VendorForm />
                    
                </Box>

                <Box width='40%' gap="50px">
                <Button onClick={() => { handleOpen("deleteVendorConfirmation") }}  color="secondary" variant="outlined"> Delete Vendor: {currentVendor ? currentVendor: "No Vendor Selected"} </Button>
                    <List subheader="Vendors" padding="20px">
                        {vendorList}
                    </List>
                </Box>
                {<ConfirmDialogue
                    open={open.deleteVendorConfirmation}
                    onClose={() => { setOpen(prevOpen => ({
                        ...prevOpen, deleteVendorConfirmation: false
                    }))}}
                    title={"Confirm Delete Vendor"}
                    body="Are You Sure You Want to Delete This Vendor??"
                    onClick={handleDeleteVendor}
                    buttonText="Confirm"
                />
                }
            </Box>
        </Box>
    )
}
export default VendorPage
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"




const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})

const getStore = async () => {
    const response = await api.get("/stores")
    return response.data
}

export const StoresQuery =  (onSuccess, onError) => {
    const api = axios.create({
        baseURL: "https://api.sellrconsultingsoftware.net"
      })
    const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();
    
    const getStore = async () => {

        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
            },
        })

        const response = await api.get("/stores", {
            headers:{ 
              'Content-Type': 'application/json',
          'authorization': `Bearer ${accessToken}`}
          })
        return response.data
    }

    return useQuery({
    queryKey: ["stores"],
    queryFn: () => getStore()
})}

import { Box,Badge, CircularProgress, Button, IconButton, Popover, Typography, useTheme, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material"
import {useContext, useState,useEffect} from 'react'
import {ColorModeContext, tokens} from "../themes"
import {InputBase} from "@mui/material"
import axios from "axios"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import SearchIcon from "@mui/icons-material/Search"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Profile from "../scenes/Profile"
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"
import { useAuth0 } from "@auth0/auth0-react"
import { BrowserRouter, Route, Routes, useNavigate, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"
import {IncomingOrders} from "../Hooks/useIncomingQuery"
import {PrivateScopedQuery} from "../Hooks/usePrivScopedQuery"
import { jwtDecode } from "jwt-decode"

import {useStore} from "../Store/Zustand"


const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})



const Item = ({title,icon, to, onClick}) => {
    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
    return(
        <List sx={{backgroundColor: colors.primary[600]}}>
            <ListItem  style={{color: colors.blueAccent[300], backgroundColor: colors.primary[600], height: "40px"}} >
            {icon} 
                <ListItemButton 
                icon = {<HelpOutlinedIcon />}
                component = {Link} 
                to = {to}
                onClick = {onClick}
                >
                    
                <ListItemText primary={title} sx={{color:colors.greenAccent[500]}}/>
                </ListItemButton>
            </ListItem>
        </List> 
    
       
    )
}


export default function Topbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
      };
    const handleClose2 = () => {
        setAnchorEl2(null);
      };

      const open = Boolean(anchorEl);
      const open2 = Boolean(anchorEl2);
      const id = open ? 'simple-popover' : undefined;
      const id2 = open2 ? 'simple-popover' : undefined;
      const [newOrders, setNewOrders] = useState(null)
      const [loading, setLoading] = useState(true)

 
  const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();


console.log(user)
// const privateScopedQuery = PrivateScopedQuery()
const incomingOrdersQuery = IncomingOrders()

// if(privateScopedQuery.isLoading) return console.log("Loading Private...")




// if(privateScopedQuery.isError) console.log(`PrivateScoped Error${privateScopedQuery.error}`);

if(incomingOrdersQuery.isLoading) return console.log("loading Incoming Orders")
if(incomingOrdersQuery.isError) return console.log("Incoming Orders Error");



const data = JSON.parse(incomingOrdersQuery.data)





    return(
    <Box display="flex" justifyContent="space-between" p={2}>
        <Box 
        display="flex" 
        backgroundColor={colors.primary[400]} 
        borderRadius= "3px"
      
        >
            <InputBase sx={{ml: 2, flex: 1}} placeholder="Search" />
            <IconButton type ="button" sx={{p:1}}> 
                <SearchIcon />
            </IconButton>
          
           
        </Box>

    <Box display="flex" >
          
       <IconButton  onClick={handleClick2}>
        <Badge variant="dot" anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}} badgeContent ={data?.length}  color="secondary">
        <NotificationsOutlinedIcon />
        </Badge>
       </IconButton>
       <Popover
        id={id}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
      >
        <Item 
        icon = {<LocalShippingIcon />}
          title={ "Unfilled Orders ("+ data?.length +")"}
          to="/IncomingOrders"
        />
        </Popover>
       


       {/* <IconButton aria-describedby={id} >
        <SettingsOutlinedIcon />
        
        </IconButton> */}
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
      >
        <Item 
        icon = {<AdminPanelSettingsIcon />}
          title="User Settings"
          to="/Profile"
        />
        <Item 
          icon = {<HelpOutlinedIcon />}
          title="FAQ"
          to="/FAQ"
        />
        <Item 
          title="Profile Form"
          to="/Form"
          icon={<PersonOutlinedIcon />}
        /> 
        <Item 
          title="Manage Team"
          to="/Team"
          icon={<PeopleOutlinedIcon />}
      
        />
        <Item 
          title="Register"
          to="/Register"
          icon={<PeopleOutlinedIcon />}
      
        />
        <Item 
          title="Pdf"
          to="/PdfFile"
          icon={<PeopleOutlinedIcon />}
      
        />
        <Item 
          title="Privacy Policy"
          to="/Privacy"
          icon={<PeopleOutlinedIcon />}
      
        />
        <Item 
          title="Logout"
          onClick= {()=>logout({ logoutParams: { returnTo: window.location.origin } })}
          icon={<LogoutIcon />}
      
        />
       
      </Popover>
        {isAuthenticated ? 
        <Box
        component="img"
        borderRadius = "50%"
        onClick={handleClick}
        sx={{
          height: 50,
          width: 50,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          "&:hover": {
            cursor: "pointer"
          }
        }}
        alt="User Profile"
        src={user.picture}
        // onClick= {()=>logout({ logoutParams: { returnTo: window.location.origin } })}
      />
        
        :
      <Button
        onClick = {()=> loginWithRedirect()}
        variant = "outlined"
        sx={{backgroundColor:colors.blueAccent[500]}}> 
        Login
        </Button>
}
    </Box>
    </Box> 
    )
}




// useEffect(() => {
//   const getUserMetadata = async () => {
//     const domain = "dev-k0oycjolh26a2qqo.us.auth0.com";

//     try {
//       const accessToken = await getAccessTokenSilently
//       ({
//         authorizationParams: {
          
//         },
//       });
      
//       // audience: `api.sellrconsultingsoftware.net/api`,
//       //     scope: "read:users read:roles read:role_members",
//       // const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
     
//       // const metadataResponse = await fetch(userDetailsByIdUrl, {
//       //   headers: {
//       //     Authorization: `Bearer ${accessToken}`,
//       //   },
//       //   param:{
//       //     scope: "read:users read:messages"
//       //   }
//       // });
      
//       // const user_metadata = await metadataResponse.json();
      
//       // setUserData(user_metadata)
      
//       setAccessToken(accessToken);

//     } catch (e) {
//       console.log(e.message);
//     }
//   };

//   getUserMetadata();
// }, [getAccessTokenSilently, user?.sub]);



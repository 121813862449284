import { Box,Badge, CircularProgress, Button, IconButton, Popover, Typography, useTheme, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material"
import {useContext, useState,useEffect} from 'react'
import {ColorModeContext,theme, tokens} from "../../themes"
import {InputBase} from "@mui/material"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { BrowserRouter, Route, Routes, useNavigate, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"
import Header from "../../Components/Header";
import StatBox from "../../Components/StatBox"
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChatIcon from '@mui/icons-material/Chat';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';


export default function Landing(){
    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
    const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();
    return(
        <Box m = "20px">
            <Header title="Sellr Consulting" subtitle="Meeting All Your Amazon Selling Needs" />
            <Box m="20px" paragraph = "false" width="80%" color="secondary">
            <Typography variant = "h3">Our software will allow you to access everything you need from our dashboard, allowing you to list products, manage customer questions and inquiries, view your store health performance, and see a detailed spreadsheet of your profit and loss—with refunds and other order information taken directly from Amazon to ensure accuracy. 
                All this is integrated into our WMS solution which allows you to do everything from automating your pricing and fulfilling orders through our app</Typography>
            </Box>
            <Box
                    display = "grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap='20px'
                    >

            <Box
                gridColumn = "span 4"
                backgroundColor = {colors.primary[500]}
                display = "flex"
                alignItems = "center"
                justifyContent = "center"
            >
                <StatBox 
                    subtitle = "Manage Inventory"                   
                    icon = {<LocalShippingIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                />
            </Box> 
            <Box
                gridColumn = "span 4"
                backgroundColor = {colors.primary[500]}
                display = "flex"
                alignItems = "center"
                justifyContent = "center"
            >
                <StatBox 
                    subtitle = "Communicate seamlessly with customers"                   
                    icon = {<ChatIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                />
            </Box>
            <Box
                gridColumn = "span 4"
                backgroundColor = {colors.primary[500]}
                display = "flex"
                alignItems = "center"
                justifyContent = "center"
            >
                <StatBox 
                    subtitle = "View aggregated customer feedback and reviews along with store health"                   
                    icon = {<AnnouncementIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                />
            </Box>  
            <Box
                gridColumn = "span 4"
                backgroundColor = {colors.primary[500]}
                display = "flex"
                alignItems = "center"
                justifyContent = "center"
            >
                <StatBox 
                    subtitle = "Automate financial reporting for orders and refunds"               
                    icon = {<RequestQuoteIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}} />}
                />
            </Box> 
            <Box
                gridColumn = "span 4"
                backgroundColor = {colors.primary[500]}
                display = "flex"
                alignItems = "center"
                justifyContent = "center"
            >
                <StatBox 
                    subtitle = "Get recommendations for re-pricing your listings"                   
                    icon = {<CurrencyExchangeIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                />
            </Box> 
            <Box
                gridColumn = "span 4"
                backgroundColor = {colors.primary[500]}
                display = "flex"
                alignItems = "center"
                justifyContent = "center"
            >
                <StatBox 
                    subtitle = "100% Free!"                   
                    icon = {<AttachMoneyIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                />
            </Box> 
            <Box gridColumn = "Span 6" />
        <Box display="flex"  justifyContent="center" mt="20px" >
            <Button
            size="large"
          
        onClick = {()=> loginWithRedirect()}
        variant = "outlined"
        sx={{backgroundColor:colors.blueAccent[600]}}> 
        <b>Login/SignUp!</b>
        </Button>
        </Box>
            </Box>
        </Box>
    )
}

// https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.5d6b49f7-026f-4558-a752-f30b4c83824f
import { Box, CardActionArea, Backdrop, FormHelperText, Modal, CardActions, TextField, Paper, Stack, Card, CardContent, CardHeader, Grid, OutlinedInput, InputLabel, Button, MenuItem, FormControl, Select } from "@mui/material"
import { useEffect , useState} from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens, theme } from "../../themes";
import Header from "../../Components/Header";
import { useTheme } from "@mui/material"

import { Typography } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import { DateRangePicker, CustomProvider } from 'rsuite';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StatBox from "../../Components/StatBox";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import ProgressCircle from '../../Components/ProgressCircle'
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import "rsuite/dist/rsuite.css";
import { set } from "date-fns";
import { getStoreList, getIncomingOrders, getWarehouseReceiving, getStore } from "../../Globals/api"
import { useQuery } from "@tanstack/react-query"
import { StoresQuery } from "../../Hooks/useStoresQuery"
import { useReportQuery } from "../../Hooks/useReportQuery"
import { Inventory } from "@mui/icons-material";



export default function InventoryPage() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [inventory, setInventory] = useState("")
    //inventory table columns
    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "store_id",
            headerName: "Store Name",
            flex: 1,
        },
        {
            field: "asin",
            headerName: "SKU",
            flex: 1,
        },
        {
            field: "product_cost",
            headerName: "Name",
            valueFormatter: (product_cost) => "$ " + parseFloat(product_cost.value).toFixed(0),
            headerAlign: "left",
            header: "left",
            flex: 1,
        },
        {
            field: "revenue",
            headerName: "UPC",
            valueFormatter: (revenue) => "$ " + parseFloat(revenue.value).toFixed(0),
            headerAlign: "left",
            align: "left"
        },
        {
            field: "fees",
            headerName: "MFN",
            valueFormatter: (fees) => "$ " + parseFloat(fees.value).toFixed(0),
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "profit",
            headerName: "Height",
            valueFormatter: (profit) => "$" + parseFloat(profit.value).toFixed(0),
            headerAlign: "left",
            align: "left",
        },
        {
            field: "roi",
            headerName: "Lenght",
            valueFormatter: (roi) => "$" + parseFloat(roi.value).toFixed(0),
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "order_created",
            headerName: "Width",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "order_last_updated",
            headerName: "Weight (oz)",
            flex: 1,
            cellClassName: "name-column--cell"
        },
        {
            field: "order_created",
            headerName: "Price",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "order_created",
            headerName: "Quantity Available",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "order_created",
            headerName: "Location",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "order_created",
            headerName: "Last Cycle Count",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
    ]
    return (
        <Box m="20px">
            <Header title="Inventory" subtitle="See Available Inventory" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.primary[500],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[500]
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.primary[500]
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`
                    }
                }}
            >
                {/* Data Grid Table */}
                {/* <DataGrid
                    rows={financialData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                /> */}
            </Box>
        </Box>
    )
}
import { Box, Button, List, Divider,Typography, TextField, useTheme } from "@mui/material"
import { useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import { tokens, theme } from "../themes";
import { GetVendorQuery } from "../Hooks/useVendorQuery"
import axios from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import UnitListItem from "./UnitListItem"
import Header from "../Components/Header"


const initialValues = {
    vendorName: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    email: "",
    contact: "",
    website: "",
    repName: "",
    repNumber: "",
    repEmail: "",
    notes: ""



}
const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})



const postNewVendor = async (submitToReceivedData) => {

    const response = await api.post("/vendors",
        submitToReceivedData
    )
    return response
}


const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const userSchema = yup.object().shape({
    vendorName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    contact: yup.
        string().
        matches(phoneRegExp, "Phone Number is Not Valid")
        .required("required"),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    website: yup.string(),
    repNumber: yup.string().matches(phoneRegExp, "Phone Number is Not Valid"),
    repEmail: yup.string().email("invalid email"),
    note: yup.string()
})

const VendorForm = () => {

    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width: 600px)")
    const [selectedIndexVendor, setSelectedIndexVendor] = useState(0);



    const vendorQuery = GetVendorQuery()

    const submitNewVendor = useMutation({
        mutationFn: postNewVendor,
        onSuccess: data => {
            queryClient.invalidateQueries(["vendors"])
        }
    })

    if (vendorQuery.isLoading) return console.log('waiting')
    if (vendorQuery.isError) return console.log('failed')

    const vendorData = vendorQuery.data.data
    console.log(vendorData)
  
    const handleListItemClick = (event, index) => {
        setSelectedIndexVendor(index);

    };

    let vendorList = vendorData?.map(function (vendor, i){
        return <><UnitListItem key = {vendor.vendor_name} primary = {"- " + vendor.vendor_name} secondary = {vendor.vendor_information.contact}  selected={selectedIndexVendor === i} onClick={(event) => handleListItemClick(event, i)} /> <Divider variant = "fullWidth" /> </>;
    })


    const handleNewVendorSubmit = (value, {resetForm}) => {
        const submitToReceivedData = {
            vendor_name: value.vendorName,
            vendor_info: {
                address: value.address,
                city: value.city,
                state: value.state,
                zipcode: value.zipcode,
                email: value.email,
                contact: value.contact,
                website: value.website,
                repName: value.repName,
                repNumber: value.repNumber,
                repEmail: value.repEmail,
            },
            notes: value.notes

        };

        submitNewVendor.mutate(submitToReceivedData)
        resetForm({ values: initialValues })

    }
    return (
        <>
        {/* <Header title="Add A Vendor" subtitle="SKUs Must Have A Vendor To Be Created" /> */}
            <Formik
                onSubmit={handleNewVendorSubmit}
                initialValues={initialValues}
                validationSchema={userSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            
                            gap="20px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}>
                            <Typography sx={{ gridColumn: "span 4" }} varaint="h2"> <u>Vendor Company Information</u></Typography>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Company Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.vendorName}
                                name="vendorName"
                                error={!!touched.vendorName && !!errors.vendorName}
                                helperText={touched.vendorName && errors.vendorName}
                                sx={{
                                    gridColumn: "span 4"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address}
                                name="address"
                                error={!!touched.address && !!errors.address}
                                helperText={touched.address && errors.address}
                                sx={{
                                    gridColumn: "span 2"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="City"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                name="city"
                                error={!!touched.city && !!errors.city}
                                helperText={touched.city && errors.city}
                                sx={{
                                    gridColumn: "span 2"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="State"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.state}
                                name="state"
                                error={!!touched.state && !!errors.state}
                                helperText={touched.state && errors.state}
                                sx={{
                                    gridColumn: "span 2"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Zip Code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.zipcode}
                                name="state"
                                error={!!touched.zipcode && !!errors.zipcode}
                                helperText={touched.zipcode && errors.zipcode}
                                sx={{
                                    gridColumn: "span 2"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Vendor Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{
                                    gridColumn: "span 4"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Contact Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contact}
                                name="contact"
                                error={!!touched.contact && !!errors.contact}
                                helperText={touched.contact && errors.contact}
                                sx={{
                                    gridColumn: "span 4"
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Website"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.website}
                                name="website"
                                error={!!touched.website && !!errors.website}
                                helperText={touched.website && errors.website}
                                sx={{
                                    gridColumn: "span 4"
                                }}
                            />
                            <Box display="grid" width="100%" gap="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gridColumn="span 4">
                                <Typography sx={{ gridColumn: "span 4" }}> <u>Vendor Representative Information </u></Typography>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Representative Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.repName}
                                    name="repName"
                                    error={!!touched.repName && !!errors.repName}
                                    helperText={touched.repName && errors.repName}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Representative Number"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.repNumber}
                                    name="repNumber"
                                    error={!!touched.repNumber && !!errors.repNumber}
                                    helperText={touched.repNumber && errors.repNumber}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Representative Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.repEmail}
                                    name="repEmail"
                                    error={!!touched.repEmail && !!errors.repEmail}
                                    helperText={touched.repEmail && errors.repEmail}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                            </Box>
                            <Typography sx={{ gridColumn: "span 4" }}> <u>Notes: </u></Typography>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.notes}
                                name="notes"
                                error={!!touched.notes && !!errors.notes}
                                helperText={touched.notes && errors.notes}
                                sx={{
                                    gridColumn: "span 4"
                                }}
                            />



                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button type="submit" color="secondary" variant="outlined" >
                                    Add New Vendor
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
            
        </>
    )
}
export default VendorForm
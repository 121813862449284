import {Box, Typography, useTheme, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar} from '@mui/material'
import { tokens } from "../themes"
import PersonIcon from '@mui/icons-material/Person';



const NewClientList = ({ primary, secondary, icon,src}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return(
        <Box width="100%" m="0 30px">
            <ListItem>
        <ListItemAvatar>
          <Avatar >
           <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary}/>
      </ListItem>
               
           <Divider width="80%" />
        </Box>
    )
}

export default NewClientList
import { FormControl, Box, CircularProgress, Select, MenProps, useTheme, MenuItem, InputLabel, OutlinedInput, FormHelperText } from "@mui/material"
import { tokens } from "../themes"
import React, { useEffect } from 'react'
import { getStoreList } from "../Globals/api";
import { StoresQuery } from "../Hooks/useStoresQuery"
import { useStore } from "../Store/Zustand"



export default function StoreSelect() {
  const [storeName, setStoreName] = React.useState([])
  const [clientInfo, setClientInfo] = React.useState([])

  function getStyles(storeNames, storeName, theme) {
    return {
      fontWeight:
        storeName.indexOf(storeNames) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const storeexp = useStore((state => state.store))
  const setStore = useStore((state => state.setStore))

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const storesQuery = StoresQuery()
  if (storesQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
  if (storesQuery.isError) return <pre>{JSON.stringify(storesQuery.error)}</pre>
  const storesDataNoKey = JSON.parse(storesQuery.data)


  let responseHandle = (data) => {
    let index = 0;
    for (let key in data) {
      data[key].id = index++;
    }

    return data
  }

  const storesData = responseHandle(storesDataNoKey)
  const storeNames = storesData.map((x) => x.store_id)







  //Store Select Tools
  const MenuProps = {
    PaperProps: {
      style: {
        width: 200,
        maxHeight: 200,
        bgcolor: colors.blueAccent[700]

      },
    },
  };
  const handleStoreChange = (event) => {
    const {
      target: { value },
    } = event;
    setStore(event.target.value)
    setStoreName(
      typeof value === 'string' ? value.split(',') : value,

    )
  }

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      {storeNames ? <InputLabel id="storeSelect">Store</InputLabel> : ""}
      <Select
        labelId="storeSelect"
        id="storeSelects"
        displayEmpty
        label={storeNames ? "Store" : ""}
        value={storeNames}
        // defaultValue = {{value: storeNames[0], label:storeNames[0]}}
        onChange={handleStoreChange}

        input={<OutlinedInput label="Store Name" />}
        MenuProps={MenuProps}
        sx={{
          backgroundColor: colors.primary[500],
          height: 60,
          autoWidth: "true",

        }}
      >
        {storeNames.map((name) => (
          <MenuItem
            key={name}

            value={name}
            style={getStyles(storeNames, storeName, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{ marginLeft: 3, }}>Select Store</FormHelperText>
    </FormControl>
  )
}
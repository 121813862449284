import { Box,FormControl, FormLabel, Radio, useTheme, RadioGroup, FormControlLabel } from "@mui/material";
import Header from "../../Components/Header"
import BarChart from "../../Components/BarChart";
import { getDefaultReportFinancials, handleErrors } from "../../Globals/api";
import { useState, useEffect} from "react";
import { tokens } from "../../themes";


const Bar= () =>{
const [metricValue, setMetricValue] = useState('revenue');
const [dateValue, setDateValue] = useState('revenue');

const theme=useTheme();
const colors = tokens(theme.palette.mode);

  const handleMetricChange = (event) => {
    setMetricValue(event.target.value);
  };
  const handleDateChange = (event) => {
    setDateValue(event.target.value);
  };
    
    return(
        <Box m="20px">
            <Header title="Bar Chart" subtitle="Simple Bar Chart" />

        <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Metric</FormLabel>
            <RadioGroup
               
                name="metricGroup"
                value={metricValue}
                onChange={handleMetricChange}
                row
                sx={{
                    color: colors.blueAccent[500],
                    '&.Mui-checked': {
                        color: colors.greenAccent[500]
                      }
                }}
            >
                <FormControlLabel value="revenue" control={<Radio />} label="revenue" />
                <FormControlLabel  value="profit" control={<Radio  />} label="profit" />
                <FormControlLabel  value="fees" control={<Radio  />} label="fees" />
                <FormControlLabel  value="product_cost" control={<Radio  />} label="product_cost" />
            </RadioGroup>
        </FormControl>   
<br></br>
        <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Date</FormLabel>
            <RadioGroup
             
                name="dateGroup"
                value={dateValue}
                onChange={handleDateChange}
                row
                sx={{
                    color: colors.blueAccent[500],
                    '&.Mui-checked': {
                        color: colors.greenAccent[500]
                      }
                }}
            >
                <FormControlLabel value="day" control={<Radio />} label="day" />
                <FormControlLabel  value="week" control={<Radio  />} label="week" />
                <FormControlLabel value="month" control={<Radio />} label="month" />
                <FormControlLabel value="year" control={<Radio />} label="year" />
            </RadioGroup>
        </FormControl>  
            
            <Box height="75vh">
                <BarChart metricValue = {metricValue} dateValue = {dateValue}/>
                
            </Box>
        </Box>
    )
}

export default Bar
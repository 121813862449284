import { Box, Typography, CircularProgress, Tab, Tabs, Button, FormControl, Grid, TextField, Select, InputLabel, OutlinedInput, MenuItem, useTheme } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { tokens } from "../../themes";
import Header from "../../Components/Header";
import React, { useEffect } from 'react'
import { Formik } from "formik"
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery"
import moment from 'moment';
import StoreSelect from "../../Components/StoreSelect";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useQuery } from "@tanstack/react-query"
import { PackingData } from "../../Hooks/usePackingDataQuery"
import { GetReceiving } from "../../Hooks/useWHReceivingQuery"
import { StoresQuery } from "../../Hooks/useStoresQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import DangerousIcon from '@mui/icons-material/Dangerous';
import {WholesaleReceivingQuery} from "../../Hooks/useWholesaleReceivingQuery"



const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})



const postToPackingData = async (submitToPackedData) => {
    const response = await api.post("/receiving",
        submitToPackedData
    )
    return response
}

const postToWholesaleReceiving = async (submitToPackedData) => {
    const response = await api.post("/wholesale_receiving",
        submitToPackedData
    )
    return response
}

const postToShippingData = async (submitToShippedData) => {
    const response = await api.post("/packing",
        submitToShippedData
    )
    return response
}




const Receiving = () => {
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const [storeName, setStoreName] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [unitsReceived, setUnitsReceived] = React.useState(0);
    const [clientInfo, setClientInfo] = React.useState([])
    const [newOrders, setNewOrders] = React.useState([]);
    const [receivedOrders, setReceivedOrders] = React.useState([]);
    const [form, setForms] = React.useState({
        receiving: {
            unitsReceived: 0,
            unitsReturned: 0,
            unitsAvailable: 0
        },
        packing: {
            toPack: 0,
            toList: 0,

        },
        shipping: {
            cost: 0
        },
        addProduct: {
            ASIN: "",
            itemsOrdered: "",
            costPerItem: "",
            unitsPerItem: "",
            unitsPerAsin: "",
            sellPrice: "",
            unitsToReceive: ""
        }
    })

    const [orders, setOrders] = React.useState(
        {}
    )



    //Global Variables
    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width: 600px)")

    const submitIncToPacking = useMutation({
        mutationFn: postToPackingData,
        onSuccess: data => {
            queryClient.invalidateQueries(["incomingOrders"], ["receivingData"], ["packing"])
        }
    })


    const submitPackingToShipping = useMutation({
        mutationFn: postToShippingData,
        onSuccess: data => {
            queryClient.invalidateQueries(["receivingData"], ["packing"],)
        }
    })

    const submitWholesaleReceiving = useMutation ({
        mutationFn: postToWholesaleReceiving,
        onSuccess: data => {
            queryClient.invalidateQueries(["wholesale_receiving"])
        }
    })
   

  
        function submitReceivedLine({ID}){
            console.log(ID)
            // const incomingFilledData = {
            //     "product_uuid": ID,
            //     "units_received": unitsReceived
            // }
            // submitIncToPacking.mutate(incomingFilledData)
        }

    const storesQuery = StoresQuery()
    const packingQuery = PackingData()
    const incomingQuery = GetReceiving()
    const wholesaleReceivingQuery = WholesaleReceivingQuery()

    if (packingQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
    if (packingQuery.isError) return <pre>{JSON.stringify(packingQuery.error)}</pre>;
    if (wholesaleReceivingQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
    if (wholesaleReceivingQuery.isError) return <pre>{JSON.stringify(wholesaleReceivingQuery.error)}</pre>;
    if (incomingQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
    if (incomingQuery.isError) return <pre>{JSON.stringify(incomingQuery.error)}</pre>;
    if (storesQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
    if (storesQuery.isError) return <pre>{JSON.stringify(storesQuery.error)}</pre>

    const wholesaleReceivingData = JSON.parse(wholesaleReceivingQuery.data.data)
   console.log(wholesaleReceivingData)

    const packingData = JSON.parse(packingQuery.data)
    const incomingOrdersData = JSON.parse(incomingQuery.data)
    const storesDataNoKey = JSON.parse(storesQuery.data)


    let responseHandle = (data) => {
        let index = 0;
        for (let key in data) {
            data[key].id = index++;
        }

        return data
    }

    const storesData = responseHandle(storesDataNoKey)
    const storeNames = storesData.map((x) => x.store_id)

    //Store Select 
    const MenuProps = {
        PaperProps: {
            style: {
                width: 200,
                maxHeight: 200,
                bgcolor: colors.blueAccent[700]

            },
        },
    };
    function getStyles(storeNames, storeName, theme) {
        return {
            fontWeight:
                storeName.indexOf(storeNames) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    //Columns for Receiving
    const columnsReceiving = [
        {
            field: "line_uuid",
            headerName: "ID",
            flex: 0.5
        },
        {
            field: "product_uuid",
            headerName: "Product ID",
            flex: 0.5
        },
        {
            field: "store_id",
            headerName: "Store ID",
            flex: 1,
            cellClassName: "name-column--cell"
        },
        {
            field: "PurchaseDate",
            headerName: "Order Received",
            valueGetter: (incomingOrdersData) => incomingOrdersData.row.order_data.PurchaseDate,
            valueFormatter: (PurchaseDate) => moment(PurchaseDate.value).format("MM-DD @ HH:mm"),
            headerAlign: "left",
            align: "left"
        },

        {
            field: "line_data.title",
            headerName: "Item name",
            valueGetter: (incomingOrdersData) => incomingOrdersData.row.line_data.Title,
            headerAlign: "center",

            width: 280
        },

        {
            field: "ASIN",
            headerName: "ASIN",
            valueGetter: (incomingOrdersData) => incomingOrdersData.row.line_data.ASIN,
            width: 100

        },
        {
            field: "order_id",
            headerName: "Amazon Order ID",
            flex: 1,

        },
        {
            field: "expected_units",
            headerName: "Expected Units",
            valueFormatter: (expected_units) => parseFloat(expected_units.value).toFixed(0),
            align: "center"
        },
        {
            field: "UnitsReceived",
            headerName: "Units Received",
            renderCell: ({row}) => <TextField type = "number" value = {unitsReceived} onChange={(event) => event.target.value <= row.expected_units ?   setUnitsReceived(event.target.value): console.log("to many")} label = {row.order_id} variant="outlined" sx={{backgroundColor: colors.primary[500]}}></TextField>,
            headerAlign: "center",
            align: "center",
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            cellClassName: "actions",
            headerName: "Actions",
            align: "center",
            getActions: ({row}) => { 
                return [<GridActionsCellItem 
                icon = {<LocalShippingIcon />}
                label = "Update"
                sx ={{backgroundColor:colors.greenAccent[500]}}
                onSubmit = {function(event) {submitReceivedLine(row.product_uuid); setCount(prev => prev + 1)}}
                />
    ]}
        },
    ]
    console.log(unitsReceived)

    const fuckit = () => {

        const shippingFilledData = {
            "warehouse_sku_uuid" : wholesaleReceivingData[0].warehouse_sku_uuid,
            "units_rejected" : 0,
            "units_accepted" : 1,

        }
        submitWholesaleReceiving.mutate(shippingFilledData)
        
    }

    //Columns for Packing
    const columnsPacking = [
        {
            field: "receiving_uuid",
            headerName: "ID",
            flex: 0.5
        },
        {
            field: "product_uuid",
            headerName: "Product ID",
            flex: 0.5
        },
        {
            field: "store_id",
            headerName: "Store ID",
            flex: 1,
            cellClassName: "name-column--cell"
        },
        {
            field: "received_input_date",
            headerName: "Order Received",
            valueFormatter: (received_input_date) => moment(received_input_date.value).format("MM-DD @ HH:mm"),
            headerAlign: "left",
            align: "left"
        },

        {
            field: "line_data.title",
            headerName: "Item name",
            valueGetter: (packingData) => packingData.row.line_data.Title,
            headerAlign: "center",

            width: 280
        },

        {
            field: "ASIN",
            headerName: "ASIN",
            valueGetter: (packingData) => packingData.row.line_data.ASIN,
            width: 100

        },
        {
            field: "order_id",
            headerName: "Amazon Order ID",
            flex: 1,
        },
        {
            field: "units_per_sell_kit",
            headerName: "# Per Order",
            // valueFormatter: (order_id) => order_id.toFixed(0),
            align: "center"
        },
        {
            field: "units_to_pack",
            headerName: "Units to Pack",
            // valueFormatter: (order_id) => order_id.toFixed(0),
            align: "center"
        },

    ]
    //Forms & Inputs 
    //Receiving Inputs
    const initialValuesReceiving = {
        line_uuid: "",
        product_uuid: "",
        unitsReceived: "",
        unitsReturned: "",
        unitsAvailable: "",
    }

    const initialValuesPacking = {
        receiving_uuid: "",
        unitsPacked: "",
        shippingCost: "",

    }

    //Handle Events

    //Tab Changer
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    // Store Changer
    const handleStoreChange = (event) => {
        const {
            target: { value },
        } = event;
        setStoreName(
            typeof value === 'string' ? value.split(',') : value,

        )
    }
    //Form handlers


    const handleReceivingRowClick = (params) => {
        const savedValues =
        {
            line_uuid: params.row.line_uuid,
            product_uuid: params.row.product_uuid,
            unitsReceived: params.row.expected_units
        }
        setOrders(savedValues)


    }

    const handlePackingRowClick = (params) => {
        const savedValues =
        {
            receiving_uuid: params.row.receiving_uuid,
            unitsPacked: params.row.units_to_pack,
            shippingCost: ""
        }
        setOrders(savedValues)
        console.log(savedValues)

    }

    // Receiving to Packing

    const handleSubmitToPacking = (values, { resetForm }) => {
        const incomingFilledData = {
            "line_uuid": values.line_uuid,
            "product_uuid": values.product_uuid,
            "units_received": values.unitsReceived,
            "damaged": values.unitsReturned
        };
        submitIncToPacking.mutate(incomingFilledData)
        resetForm({ values: initialValuesReceiving })

    }
    //     //Packing to Shipping


    const handleSubmitToShipping = (values, { resetForm }) => {

        const shippingFilledData = {
            "receiving_uuid": values.receiving_uuid,
            "units_packed": values.unitsPacked,
            "shipping_cost": values.shippingCost
        };

        submitPackingToShipping.mutate(shippingFilledData)
        resetForm({ values: initialValuesReceiving })

    }




    return (
        <Box m="20px">
            <Header title="RECEIVING" subtitle="Recieve and Ship Products" />
            <Button onClick = {fuckit} sx= {{backgroundColor:colors.greenAccent[500]}} variant = "outlined" > Fuckit</Button>
            <StoreSelect />
            <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                sx={{
                    "& button": { borderRadius: 1.5 },
                    "& button:hover": { backgroundColor: colors.blueAccent[800] }
                }}
            >
                <Tab label='Receiving' icon={<CallReceivedIcon />} />
                <Tab label='Packing & Shipping' icon={<LocalShippingIcon />} />
                {/* <Tab label='Shipping' /> */}
                <Tab label='Returns' icon={<CurrencyExchangeIcon />} />

            </Tabs>


            {/* Receiving Tab */}


            {currentTabIndex === 0 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} />
                    <Grid item xs={12} />

                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={7} />

                    <Grid item xs={12}>
                        <Box
                            m="40px 0 0 0"
                            height="50vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: ".1",
                                    borderTop: ".1"
                                },


                                "& .name-column--cell": {
                                    color: colors.greenAccent[300]
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: colors.primary[500],
                                    borderBottom: "none",

                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: colors.primary[600]
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: colors.primary[600],
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${colors.grey[100]} !important`
                                },


                            }}
                        >
                            {/* receiving */}
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            // Hide columns id and lastname.
                                            // Other columns will remain visible
                                            line_uuid: false,
                                            product_uuid: false

                                        }
                                    }
                                }}
                                getRowHeight={() => 'auto'}
                                rows={incomingOrdersData}
                                columns={columnsReceiving}
                                getRowId={(row) => row.line_uuid}
                                onRowClick={handleReceivingRowClick}
                                GridLinesVisibility="None"
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important"
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: `${colors.greenAccent[800]} !important`
                                    }
                                    ,
                                }}

                            /></Box>


                        <Formik
                            onSubmit={handleSubmitToPacking}
                            initialValues={orders || initialValuesReceiving}
                            enableReinitialize
                        //  validationSchema={userSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="10px"
                                        gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
                                        }}>
                                        <Box gridColumn="span 6" />
                                        <Box gridColumn="span 4" />
                                        <InputLabel sx={{ ml: "40%", my: "auto" }}><Typography color="white">Units Received</Typography></InputLabel>
                                        <TextField
                                            fullWidth

                                            variant="outlined"
                                            type="number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={parseFloat(values.unitsReceived).toFixed(0)}

                                            name="unitsReceived"
                                            error={!!touched.unitsReceived && !!errors.unitsReceived}
                                            helperText={touched.unitsReceived && errors.unitsReceived}
                                            sx={{
                                                input: { color: colors.grey[100] },
                                                gridColumn: "span 1",
                                                backgroundColor: colors.primary[500],
                                            }}
                                        />
                                        <Box gridColumn="span 4" />


                                        <InputLabel sx={{ ml: "40%", my: "auto" }} ><Typography color="white">Units Damaged</Typography></InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            type="number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.unitsReturned}
                                            name="unitsReturned"
                                            error={!!touched.unitsReturned && !!errors.unitsReturned}
                                            helperText={touched.unitsReturned && errors.unitsReturned}
                                            sx={{
                                                gridColumn: "span 1",
                                                backgroundColor: colors.primary[500],
                                            }}

                                        />
                                        <Box gridColumn="span 4" />

                                        <InputLabel sx={{ ml: "40%", my: "auto" }}><Typography color="white">Units Available</Typography></InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            type="number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.unitsAvailable}

                                            name="unitsAvailable"
                                            error={!!touched.unitsAvailable && !!errors.unitsAvailable}
                                            helperText={touched.unitsAvailable && errors.unitsAvailable}
                                            sx={{
                                                gridColumn: "span 1",
                                                backgroundColor: colors.primary[500],
                                                '& .MuiTextField-root': { color: 'green' },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Line UUID"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.line_uuid}
                                            name="line_uuid"
                                            error={!!touched.line_uuid && !!errors.line_uuid}
                                            helperText={touched.line_uuid && errors.line_uuid}
                                            sx={{
                                                input: { cursor: 'not-allowed' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                    display: "none"
                                                },

                                                gridColumn: "span 2",
                                                display: "none",
                                                backgroundColor: colors.blueAccent[500]
                                            }}
                                            inputProps={{
                                                style: {
                                                    height: "5px",
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Product UUID"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_uuid}
                                            name="product_uuid"
                                            error={!!touched.product_uuid && !!errors.product_uuid}
                                            helperText={touched.product_uuid && errors.product_uuid}
                                            sx={{
                                                input: { cursor: 'not-allowed' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                    display: "none"
                                                },

                                                gridColumn: "span 2",
                                                display: "none",
                                                backgroundColor: colors.blueAccent[500]
                                            }}
                                            inputProps={{
                                                style: {
                                                    height: "5px",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button type="submit" variant="outlined" color="secondary"  >
                                            Receive/Return
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Grid>


                </Grid>
            )}

            {/* Packing Tab */}



            {currentTabIndex === 1 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} />
                    <Grid item xs={12} />

                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={7} />
                    <Grid item xs={12}>
                        <Box
                            m="40px 0 0 0"
                            height="50vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: ".1",
                                    borderTop: ".1"
                                },


                                "& .name-column--cell": {
                                    color: colors.greenAccent[300]
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: colors.primary[500],
                                    borderBottom: "none"
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: colors.primary[500]
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: colors.primary[600],
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${colors.grey[100]} !important`
                                },


                            }}
                        >
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            // Hide columns id and lastname.
                                            // Other columns will remain visible
                                            receiving_uuid: false,
                                            product_uuid: false

                                        }
                                    }
                                }}
                                getRowHeight={() => 'auto'}
                                onRowClick={handlePackingRowClick}
                                rows={packingData}
                                columns={columnsPacking}
                                getRowId={(row) => row.receiving_uuid}
                                checkboxSelection
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important"
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: `${colors.greenAccent[800]} !important`
                                    }
                                    ,
                                }}
                            /></Box>

                        <Formik
                            onSubmit={handleSubmitToShipping}
                            initialValues={orders || initialValuesPacking}
                            enableReinitialize
                        //  validationSchema={userSchema}
                        >

                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="10px"
                                        gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
                                        }}>
                                        <Box gridColumn="span 6" />
                                        <Box gridColumn="span 4" />
                                        <InputLabel sx={{ ml: "40%", my: "auto" }}><Typography color="white">Units to Pack/Ship: </Typography></InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.unitsPacked}
                                            name="unitsPacked"
                                            error={!!touched.unitsPacked && !!errors.unitsPacked}
                                            helperText={touched.unitsPacked && errors.unitsPacked}
                                            sx={{
                                                gridColumn: "span 1",
                                                backgroundColor: colors.primary[500],
                                            }}

                                        />
                                        <Box gridColumn="span 4" />


                                        <InputLabel sx={{ ml: "40%", my: "auto" }} ><Typography color="white">Shipping Cost: </Typography></InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"

                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.shippingCost}
                                            name="shippingCost"
                                            error={!!touched.shippingCost && !!errors.shippingCost}
                                            helperText={touched.shippingCost && errors.shippingCost}
                                            sx={{
                                                gridColumn: "span 1",
                                                backgroundColor: colors.primary[500],
                                                '& .MuiTextField-root': { color: 'green' },
                                            }}

                                        />
                                        <Box gridColumn="span 4" />


                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Receiving UUID"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.receiving_uuid}
                                            name="receiving_uuid"
                                            error={!!touched.receiving_uuid && !!errors.receiving_uuid}
                                            helperText={touched.receiving_uuid && errors.receiving_uuid}
                                            sx={{
                                                input: { cursor: 'not-allowed' },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                    display: "none"
                                                },

                                                gridColumn: "span 1",
                                                display: "none",
                                                backgroundColor: colors.primary[500]
                                            }}

                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button type="submit" variant="outlined" color="secondary"  >
                                            Pack & Ship
                                        </Button>
                                    </Box>

                                </form>
                            )}
                        </Formik>
                    </Grid>


                </Grid>

            )}
        </Box>

    )
}
export default Receiving
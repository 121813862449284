import {Box, CardActionArea,Backdrop, FormHelperText,  Modal, CardActions, TextField, Paper, Stack, Card,CardContent, CardHeader, Grid, OutlinedInput, InputLabel, Button, MenuItem, FormControl, Select} from "@mui/material"
import React, { useEffect } from 'react'
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens, theme } from "../../themes";
import Header from "../../Components/Header";
import { useTheme } from "@mui/material"
import "./index.css" 
import { Typography } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import { DateRangePicker,CustomProvider } from 'rsuite';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StatBox from "../../Components/StatBox";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import ProgressCircle from '../../Components/ProgressCircle'
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import "rsuite/dist/rsuite.css";
import { set } from "date-fns";
import {getStoreList, getIncomingOrders, getWarehouseReceiving, getStore} from "../../Globals/api"
import { useQuery } from "@tanstack/react-query"
import {StoresQuery} from "../../Hooks/useStoresQuery"
import {useReportQuery} from "../../Hooks/useReportQuery"



function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };




const Financials =() =>{
     
    const [financialData, setFinancialData] = React.useState([])
    const [financialDataTotal, setFinancialDataTotal] = React.useState([]) 
    const [totalExpenses, setTotalExpenses] = React.useState([])
    const [date, setDate] = React.useState([
    ]) //state for calendar
    const [isCollapsed, setIsCollapsed] = React.useState(true) //probably unnecessary
    const [storeName, setStoreName] = React.useState([])
    const [clientInfo, setClientInfo] = React.useState([]) //state for API call for all client data
    const [fees, setFees] = React.useState([])
    const [open, setOpen] = React.useState({
        profit: false,
        revenue: false,
        productCost: false,
        fees: false
    })
    const [loading, setLoading] = React.useState(true)
    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
   

        //      useEffect(() =>{
        //     getReportFinancials
        //      .then(handleErrors)
        //         .then(res => {
        //         return res.clone().json();
        //         })
        //         .then (data => responseHandle(data))
        //         .catch(function(error) {
        //             console.log(error);
        //         });
        //     let responseHandle = (data) => {
        //     data = JSON.parse(data)
        //     console.log(data)
        //     setFinancialDataTotal(data.totals)
        //     setTotalExpenses(data.service_fees_by_store.storeName)
        //     let index = 0;
        //     for (let key in data.table_data) {
        //        data.table_data[key].id = index++;
        //     }
        //     setFinancialData(data.table_data);
        //      }
        //      setLoading(false)

        //    }, [date, storeName]);

        

      let startTime = date[0]  
      let endTime = date[1]   
      

      function startOfMonth(date){
         return new Date(date.getFullYear(), date.getMonth(), 1);
        }
              let dt = new Date();
       let defaultStart = startOfMonth(dt).toISOString()
       let defaultEnd = new Date().toISOString()
     
       const checkStart = (startTime)=>{
        if (startTime === undefined){
            return defaultStart}
        return startTime
      }
      const checkEnd = (endTime)=>{
        if (endTime === undefined){
            return defaultEnd}
        return endTime
      }
    //  let storeNameApi = storeName.length === 0 ? storeNames[0]: storeName
                //  const reportData = {
                //      method: 'GET',
                //      headers: { 
                //          'Content-Type': 'application/json' },
                    
                //  };
                //  const getReportFinancials = fetch('https://api.sellrconsultingsoftware.net/report?' + new URLSearchParams({
                //      start_date: startTime === undefined? defaultStart : startTime,
                //      end_date: endTime === undefined? defaultEnd: endTime,
                //      store_id: 'Worth Services LLC',
     
                     
                //  }, reportData))

    let checkStartTime= checkStart()
    let checkEndTime = checkEnd()
    

    const reportQuery = useReportQuery(checkStartTime,checkEndTime)
    const storesQuery = StoresQuery()

    if(storesQuery.isLoading) return  console.log("stores are loading");
    if(storesQuery.isError)  console.log(`StoreQuery() Error: ${storesQuery.error}`);
    if(reportQuery.isLoading) return  console.log("Reports are loading");
    if(reportQuery.isError)  console.log(`ReportQuery() Error: ${reportQuery.error}`);


    
    const financeData =  JSON.parse(reportQuery.data) 
   
    
      console.log(financeData)
      const storesDataNoKey = JSON.parse(storesQuery?.data)
      

      let responseHandle = (data) => {
          let index = 0;
          for (let key in data) {
              data[key].id = index++;
          }
          
          return data
      }
      
      const storesData = responseHandle(storesDataNoKey)
      const storeNames = storesData.map((x) => x.store_id)
      
  
 
      
      function replaceNaNWithZero(obj) {
        let newObj = {};
        for (let key in obj) {
          newObj[key] = obj[key];
          if (isNaN(obj[key])) {
            newObj[key] = '$0';
          }
        }
        return newObj;
      }

      let formattedGridData = financeData.table_data.map(obj => replaceNaNWithZero(obj))
      

    //Column Names for Data Table
        const columns = [
        
        {
        field: "store_id", 
        headerName: "Store Name", 
        flex: 1, 
        },
        {
            field: "order_id", 
            headerName: "Order ID"}
        ,
        {
        field: "asin",
        headerName: "ASIN",
        flex: 1,
        },
        {
        field: "product_cost",
        headerName: "Product Cost",
        valueFormatter: (product_cost) => "$ " + parseFloat(product_cost.value).toFixed(0),
        headerAlign: "left",
        header: "left",
        flex: 1,
        },          
        {
        field: "revenue", 
        headerName: "Revenue",
        valueFormatter: (revenue) => "$ " + parseFloat(revenue.value).toFixed(0),
        headerAlign: "left",
        align: "left"},
        {
        field: "fees", 
        headerName: "Amazon Fees", 
        valueFormatter: (fees) => "$ " + parseFloat(fees.value).toFixed(0) ,
        headerAlign: "left",
        align: "left",
        flex: 1, 
        },
        {
        field: "profit", 
        headerName:"Profit", 
        valueFormatter: (profit) => "$" + parseFloat(profit.value).toFixed(0),
        headerAlign: "left",
        align: "left",},
        {
        field: "roi", 
        headerName: "ROI", 
        valueFormatter: (roi) => "$" + parseFloat(roi.value).toFixed(0),
        headerAlign: "left",
        align: "left",
        flex: 1, 
        },
         {
        field: "order_created", 
        headerName: "Order Created", 
        headerAlign: "left",
        align: "left",
        flex: 1, 
        },
        {
        field: "order_last_updated", 
        headerName: "Last Updated", 
        flex: 1, 
        cellClassName: "name-column--cell"},
    ]

    //Calendar Tools
    const handleClick = () =>{
        setIsCollapsed(!isCollapsed)
    }
    
    const handleDateChangeStart = (value) =>{
       
        let startTime = value[0].toISOString()
        let endTime = value[1].toISOString()
       
        // value[1].toISOString()
        setDate([startTime, endTime])
        
    }
   
 //Store Select Tools
 const MenuProps = {
    PaperProps: {
      style: {
        width: 200,
        maxHeight: 200,
        bgcolor: colors.blueAccent[700]
        
      },
    },
  };


    function getStyles(storeNames, storeName, theme) {
  return {
    fontWeight:
      storeName.indexOf(storeNames) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};


   
   const handleStoreChange = (event) => {
    const {
      target: { value },
    } = event;   
    setStoreName(
      typeof value === 'string' ? value.split(',') : value,
      
    )
   
   }

   //Modal Tools

   const handleOpen = (event) => {
    if (event=== "profit") {
        setOpen(prevOpen => ({
            ...prevOpen, profit: true
        } ))
    }else if (event === "revenue"){
        setOpen(prevOpen => ({
            ...prevOpen, profit: true
        } ))
    }else if (event==="fees") {
        setOpen(prevOpen => ({
            ...prevOpen, fees: true
        } )) 
    }   
    else if (event==="productCost") {
        setOpen(prevOpen => ({
            ...prevOpen, productCost: true
        } )) 
    } 
    
   };
   const handleClose = (event) => {
    if (event=== "profit") {
        setOpen(prevOpen => ({
            ...prevOpen, profit: false
        } ))
    }else if (event === "revenue"){
        setOpen(prevOpen => ({
            ...prevOpen, profit: false
        } ))
    }else if (event==="fees") {
        setOpen(prevOpen => ({
            ...prevOpen, fees: false
        } )) 
    }
    else if (event==="productCost") {
        setOpen(prevOpen => ({
            ...prevOpen, productCost: false
        } )) 
    }      
   };
  
  
    return(
        <Box m="20px">
            <Header title="AMAZON FINANCIALS" subtitle="List of Financials" />
           
            <Box
                    display = "grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap='20px'
                    >
            
             
                        <Box gridColumn = "span 2" />
                    <Box
                    gridColumn = "span 4"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
        
                    <StatBox 
                    title = {"$" + parseFloat(financeData.totals?.profit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    subtitle = "Profit"
                    progress = "0.75"
                    increase = "14%"
                    icon = {<AttachMoneyIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
               
                    <Box
                    gridColumn = "span 4"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
        
        
                    <StatBox 
                    title = {parseFloat(financeData.totals?.items_shipped).toFixed(0)}
                    subtitle = "Items Shipped "
                    progress = "0.75"
                    icon = {<LocalShippingIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                
                    
                    />
                    </Box>
                    <Box gridColumn = "span 2" />
                    
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
                        
        
                    <StatBox 
                    title = {"$" + parseFloat(financeData?.totals.revenue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    subtitle = "Total Revenue"
                    progress = "0.75"
                    increase = "14%"
                    icon = {<CallReceivedIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
        
                    <StatBox 
                    title = {"$" + parseFloat(financeData?.totals.product_cost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }
                    subtitle = "Product Cost "
                    progress = "0.75"
                    increase = "3%"
                    icon = {<CallReceivedIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
        
                    <StatBox 
                    title = {parseFloat(financeData?.totals.roi).toFixed(2)}
                    subtitle = "ROI "
                    progress = "0.75"
                    increase = "14%"
                    icon = {<PercentIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 
                    <Box
                    gridColumn = "span 3"
                    backgroundColor = {colors.primary[500]}
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
        
                    <StatBox 
                    title = {"$" + parseFloat(financeData?.totals.fees).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    subtitle = "Fees "
                    progress = "0.75"
                    increase = "14%"
                    icon = {<CallReceivedIcon sx ={{ color: colors.greenAccent[300],fontSize:"26px"}}/>}
                    />
                    </Box> 

                    <Box
                    gridColumn = "span 3"
                    display = "flex"
                    alignItems = "center"
                    justifyContent = "center"
                    >
                         <CustomProvider theme="dark">
                             <DateRangePicker size = "lg" value = {date.startDate} format="MM-dd-yyyy" character=" : " onChange={handleDateChangeStart} theme="dark" appearance="default" placeholder="Default" style={{ width: 230 }} />
                            </CustomProvider>
                    
                        </Box>
                        <Box
                         gridColumn = "span 6"
                         display = "flex"
                         alignItems = "center"
                         justifyContent = "center"
                         />
                        <Box
                         gridColumn = "span 3"
                         display = "flex"
                         alignItems = "center"
                         justifyContent = "center"
                        >
                           <FormControl sx={{ m: 1, width: 300  }}>
                           {storeNames? <InputLabel id="storeSelect">Store</InputLabel> : ""}
                        <Select
                            labelId="storeSelect"
                            id="storeSelects"
                            displayEmpty
                            label= {storeNames ? "Store" : ""}
                            value={storeNames}
                            // defaultValue = {{value: storeNames[0], label:storeNames[0]}}
                            onChange={handleStoreChange}
                
                            input={<OutlinedInput label="Store Name" />}
                            MenuProps={MenuProps}
                            sx ={{
                                backgroundColor: colors.primary[500],
                                height: 60,
                                autoWidth: "true",
                
                            }}
                            >
                            {storeNames.map((name) => (
                                <MenuItem
                                key={name}
                                
                                value={name}
                                style={getStyles(storeNames, storeName, theme)}
                                >
                                {name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{marginLeft: 3,}}>Select Store</FormHelperText>
                        </FormControl>

                        </Box>
                    </Box>
          
        
                        
            
            <Box 
            m="40px 0 0 0" 
            height = "75vh" 
            sx={{
                "& .MuiDataGrid-root" : {
                    border: "none"
                },
                "& .MuiDataGrid-cell" : {
                    borderBottom : "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.primary[500],
                    borderBottom: "none"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[500]
                },
                "& .MuiDataGrid-footerContainer" :{
                    borderTop: "none",
                    backgroundColor: colors.primary[500]
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
            }} 
            >
            {/* Data Grid Table */}
                <DataGrid 
                rows = {financeData.table_data}
                columns = {columns}
                getRowId = {(row) => row.order_id}
                components={{Toolbar: GridToolbar}}
                />
            </Box>
        </Box>

    )
}

export default Financials



// return(
//     <Box m="20px">
//         <Header title="AMAZON FINANCIALS" subtitle="List of Financials" />
       
//         <Grid
//         container
//         direction="columns" 

//         >
//             <Grid
//             container
//             item
            
//             spacing={4}
//             xs={12}>
//                 <Grid item xs={4.5} />

//                 <Grid item xs={3} >
//                     <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
                        
//                         <CardActions>

//                             <CardHeader align ="center" title="Profit" />
//                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                 <Typography align= "center"  color="secondary" variant="h2">
//                                 ${financialDataTotal.profit}
//                                 </Typography>
//                             </CardContent>
                            

//                             </CardActions>
                       
                        
                        
//                     </Card>
//                 </Grid>
                
//                  <Grid item xs={4.5} />
                
                
//                 {/* Line 2 */}
//                 <Grid xs={2} />

//                 <Grid item xs={2}>
//                 <Card  variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                     <CardActionArea>
//                         <CardHeader  align= "center"  title="Revenue"  />
//                         <CardContent onClick={() => { handleOpen("revenue"); }} >
//                             <Typography   align ="center" color="secondary" variant="h2">
//                             ${parseFloat(financialDataTotal.revenue).toFixed(2)}
//                             </Typography>
//                         </CardContent>
//                         <Modal
//                                 open={open.profit}
//                                 onClose={() =>{ handleClose("profit") }}
//                                 aria-labelledby="Profit Modal"
//                                 aria-describedby="transition-modal-description"
//                                 slots={{ backdrop: Backdrop }}
//                                 slotProps={{
//                                 backdrop: {
//                                     timeout: 500,
//                                 },
//                                 }}
//                             >
//                                <Grid  container direction = "columns" sx={modalStyle} xs={12} spacing = {2}>
//                                     <Grid item xs={1} />
//                                     <Grid item xs={10}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Profit from Sales" />
//                                             <CardContent onClick={() => { handleOpen("profit"); }} alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="secondary" variant="h2">
//                                                 $2,000.00
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
//                                     <Grid item xs={7} />
//                                 <Grid container item xs={12} />
//                                     <Grid item xs={1} />
//                                     <Grid item xs={10}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Revenue from Reimbursements" />
//                                             <CardContent onClick={() => { handleOpen("profit"); }} alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="secondary" variant="h2">
//                                                 $279.69
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
                                    
//                                 </Grid> 
//                             </Modal>
//                     </CardActionArea>
//                 </Card>
//                 </Grid>

//                 <Grid item xs={2}>
//                 <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto',  borderRadius:10}}>
//                         <CardHeader align= "center" title="Return on Investment" />
                        
//                         <CardContent  >
//                             <Typography align= "center" color="secondary" variant="h2">
//                             {parseFloat(financialDataTotal.roi).toFixed(0)}%
//                             </Typography>
//                         </CardContent>
                        
                    
//                 </Card>
//                 </Grid>

//                 <Grid item xs={2}>
//                 <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
                    
//                         <CardHeader align= "center" title="Product Cost" />
                        
//                         <CardContent>
//                             <Typography align= "center" color="#ce4c6e" variant="h2">
//                             ${parseFloat(financialDataTotal.product_cost).toFixed(2)}
//                             </Typography>
//                         </CardContent>
                  
                    
//                 </Card>
//                 </Grid>
               


//                 <Grid item xs={2}>
//                 <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                      <CardActionArea>
//                     <CardHeader align= "center" title="Fees" />
                    
//                     <CardContent onClick={() => { handleOpen("fees"); }}>
//                     <Typography align= "center" color="#ce4c6e" variant="h2">
//                     ${parseFloat(financialDataTotal.fees).toFixed(2)}
//                     </Typography>
                    
//                     </CardContent>
//                     <Modal
//                                 open={open.fees}
//                                 onClose={() =>{ handleClose("fees"); }}
//                                 aria-labelledby="Fee Modal"
//                                 slots={{ backdrop: Backdrop }}
//                                 slotProps={{
//                                 backdrop: {
//                                     timeout: 500,
//                                 },
//                                 }}
//                             >
//                                <Grid  container direction = "columns" sx={modalStyle} xs={12} spacing = {2}>
//                                     <Grid item xs={2} />
//                                     <Grid item xs={2}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Selling Fees" />
//                                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="#ce4c6e" variant="h2">
//                                                 $1,503.05
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
//                                     <Grid item xs={2}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Pick & Pack Fees" />
//                                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="#ce4c6e" variant="h2">
//                                                 $590.32
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
//                                     <Grid item xs={2}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Shipping Fees" />
//                                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="#ce4c6e" variant="h2">
//                                                 $230.40
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
                                    

//                                     <Grid item xs={1} />
//                                 <Grid container item xs={12} />
//                                     <Grid item xs={2} />
//                                     <Grid item xs={3}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Removal Fees" />
//                                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="#ce4c6e" variant="h2">
//                                                 $279.69
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
//                                     <Grid item xs={2}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Storage Fees" />
//                                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="#ce4c6e" variant="h2">
//                                                 $1050.60
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
//                                     <Grid item xs={2}>
//                                         <Card  alignContent="center" variant="outlined"  sx={{ width: 'auto', borderRadius:10}}>
//                                             <CardHeader align ="center" title="Disposal Fees" />
//                                             <CardContent  alignContent="center" sx={{alignContent:"center"}} >
//                                                 <Typography align= "center" alignContent="center" color="#ce4c6e" variant="h2">
//                                                 $340.00
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card> 
//                                     </Grid>
//                                     <Grid item xs={1} />
                                    
//                                 </Grid>
//                             </Modal>
//                     </CardActionArea>
                    
//                 </Card>
//                 </Grid>
                
                

//                 <Grid container item xs ={12} />
//                 <Grid container item xs ={12} />

                

//             </Grid>
//             <Grid 
//             container 
//             item
//             alignItems="flex-start"
//             spacing = {0.5}
//             xs={12}
//             >
//                 <Grid
//                 item 
//                 display="flex"  
//                 xs={1.5}
//                 > 
//                 <CustomProvider theme="dark">
//         <DateRangePicker value = {date.startDate} format="MM-dd-yyyy" onChange={handleDateChangeStart} size ="lg" theme="dark" appearance="default" placeholder="Default" style={{ width: 230 }} />
//         </CustomProvider>
//                 {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <MobileDatePicker
//                         label = "Start Date"
//                         value = {date.startDate}
//                         onChange={handleDateChangeStart}
//                         views={["month"]}
//                     />
//                 </LocalizationProvider> */}
//                 </Grid>
//                 <Grid
//                 item 
//                 display="flex"  
//                 xs={1.5}
//                 spacing = {10}
//                 >
//                 {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <MobileDatePicker
//                         label = "End Date"
//                         value = {date.endDate}
//                         onChange={handleDateChangeEnd} 
//                         views={["month"]}
//                     />
//                 </LocalizationProvider> */}
                
//                 </Grid>
//                 <Grid
//                 xs={3}>

//                 </Grid>
//                 <Grid>
//                 </Grid>
//             </Grid>

//             <Grid 
//                 container 
//                 alignItems="flex-start"
//                 spacing = {3}
//                 xs={12}
//                 >
                    
//                 <Grid item
//                 sx = {3}>
                    
//                     <FormControl sx={{ m: 1, width: 300  }}>
//                     {/* <InputLabel  id="storeSelect" 
//                     sx={{ typography: "h3", color: colors.grey[100]}} >Store Name
//                     </InputLabel> */}
//                     <Select
//                         labelId="storeSelect"
//                         id="storeSelects"
//                         displayEmpty
//                         label= "Select Store"
//                         value={storeNames}
//                         defaultValue = {{value: storeNames[0], label:storeNames[0]}}
//                         onChange={handleStoreChange}
//                         input={<OutlinedInput label="Store Name" />}
//                         MenuProps={MenuProps}
//                         sx ={{
//                             backgroundColor: colors.blueAccent[500],
//                             height: 60,
//                             autoWidth: "true",
            
//                         }}
//                         >
//                         {storeNames.map((name) => (
//                             <MenuItem
//                             key={name}
                            
//                             value={name}
//                             style={getStyles(storeNames, storeName, theme)}
//                             >
//                             {name}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                     </FormControl>
                    
//                     </Grid>
//                 </Grid>
//         </Grid>
            
        
//         <Box 
//         m="40px 0 0 0" 
//         height = "75vh" 
//         sx={{
//             "& .MuiDataGrid-root" : {
//                 border: "none"
//             },
//             "& .MuiDataGrid-cell" : {
//                 borderBottom : "none"
//             },
//             "& .name-column--cell": {
//                 color: colors.greenAccent[300]
//             },
//             "& .MuiDataGrid-columnHeaders": {
//                 backgroundColor: colors.blueAccent[700],
//                 borderBottom: "none"
//             },
//             "& .MuiDataGrid-virtualScroller": {
//                 backgroundColor: colors.primary[500]
//             },
//             "& .MuiDataGrid-footerContainer" :{
//                 borderTop: "none",
//                 backgroundColor: colors.blueAccent[700]
//             },
//             "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//                 color: `${colors.grey[100]} !important`
//             }
//         }} 
//         >
//         {/* Data Grid Table */}
//             <DataGrid 
//             rows = {financialData}
//             columns = {columns}
//             components={{Toolbar: GridToolbar}}
//             />
//         </Box>
//     </Box>

// )
// }
import { useRef, useState, useEffect } from "react";
import {Box, Button,styled, FormHelperText,InputLabel,  IconButton, Backdrop, Card, Tab, Alert, Tabs, inputLabelClasses, Modal, inputClasses, CardHeader, Typography,CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid} from "@mui/material"
import { tokens } from "../../themes";
import { Formik } from "formik"
import { BrowserRouter, Route, Routes, useNavigate, Link } from "react-router-dom";


export default function SignIn(){
      const theme=useTheme();
    const colors = tokens(theme.palette.mode);


    const initialValues = {
        email: "",
        password: "",   
    
    }

    

    const handleFormSubmit = (values) =>{
        console.log(values);
    }

    return(
        <Box 
            display = "grid" 
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
           
        >
            <Box gridColumn = "span 12" />
            <Box gridColumn = "span 3" />
            <Box 
                gridColumn = "span 6"
                >

               
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                <Box  display="grid"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
               
                backgroundColor = {colors.primary[500]}
                
                >
            
            
                <Typography  margin ="20px" gridColumn ="span 4" variant="h1" color="secondary"><u>SignIn</u></Typography>
                <Box gridColumn ="span 4" />

                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="text"
                autoComplete="off"
                value = {values.email}
                name="email"
                error = {!!touched.email && !!errors.email}
                helperText ={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Enter Email" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />
                
                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="password"
                id="password"
                value = {values.password}
                name="password"
                error = {!!touched.password && !!errors.password}
                helperText ={touched.password && errors.password}
                autoComplete="off"
               
                onBlur={handleBlur}
                onChange={handleChange}
                label="Enter Password" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />

              
               

                {/* <Box display="flex" justifyContent="end" mt="20px"> */}
                <Box gridColumn="span 2" />
                <Button sx={{mr:"20px", mb:"20px"}} type="submit" color="secondary" variant="outlined">
                    Sign In
                </Button>
                <Box gridColumn ="span 1" />

                <Typography 
                component={Link}
                to="/register"
                marginLeft="20px"
                marginBottom="20px"
                color="white"
                variant ="h6"
                gridColumn ="span 2"

                sx={{ "&:hover": { color: "white" } }}
                >
                    Want to Join? <b>Click Here!</b>
                </Typography>
                
             
                </Box>
                </form>
                    )}
               </Formik>
                
            </Box>




        </Box>
    )
}
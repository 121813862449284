import { useRef, useState, useEffect } from "react";
import {Box, Button,styled, FormHelperText,InputLabel,  IconButton, Backdrop, Card, Tab, Alert, Tabs, inputLabelClasses, Modal, inputClasses, CardHeader, Typography,CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid} from "@mui/material"
import { tokens } from "../../themes";
import { Formik } from "formik"
import * as yup from "yup"

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@$%]).{8,24}$/;

export default function Register(){
      const theme=useTheme();
    const colors = tokens(theme.palette.mode);


    const initialValues = {
        email: "",
        password: "",
        confirmPassword: "",
        creationToken: "",
        adminCode: "",
       
    
    }
    const userSchema = yup.object().shape({
        email: yup.string().email("invalid email").required("required"),
        password: yup.string().matches(PWD_REGEX, "Password is not Valid").required("required"),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
        creationToken: yup.string(),
        adminCode: yup.string()
       
    })
    

    const handleFormSubmit = (values) =>{
        console.log(values);
    }

    return(
        <Box 
            display = "grid" 
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
           
        >
            <Box gridColumn = "span 12" />
            <Box gridColumn = "span 3" />
            <Box 
                gridColumn = "span 6"
                >

               
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={userSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                <Box  display="grid"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
               
                backgroundColor = {colors.primary[500]}
                
                >
            
            
                <Typography  margin ="20px" gridColumn ="span 4" variant="h1" color="secondary"><u>Register</u></Typography>
                <Box gridColumn ="span 4" />

                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="text"
                autoComplete="off"
                value = {values.email}
                name="email"
                error = {!!touched.email && !!errors.email}
                helperText ={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Enter Email" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />
                
                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="password"
                id="password"
                value = {values.password}
                name="password"
                error = {!!touched.password && !!errors.password}
                helperText ={touched.password && errors.password}
                autoComplete="off"
               
                onBlur={handleBlur}
                onChange={handleChange}
                label="Enter Password" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />

                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="password"
                id="confirmPassword"
                value = {values.confirmPassword}
                name="confirmPassword"
                error = {!!touched.confirmPassword && !!errors.confirmPassword}
                helperText ={touched.confirmPassword && errors.confirmPassword}
                autoComplete="off"
               
                onBlur={handleBlur}
                onChange={handleChange}
                label="Confirm Password" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />


                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="text"
                id="creationToken"
                value = {values.creationToken}
                name="creationToken"
                error = {!!touched.creationToken && !!errors.creationToken}
                helperText ={touched.creationToken && errors.creationToken}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Creation Token" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />

                <Box gridColumn ="span 1" />
                <TextField  
                variant="filled" 
                type="text"
                id="adminCode"
                value = {values.adminCode}
                name="adminCode"
                error = {!!touched.adminCode && !!errors.adminCode}
                helperText ={touched.adminCode && errors.adminCode}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Admin Code" 
                sx={{margin:"20px",  gridColumn:"span 2"}} />
                <Box gridColumn ="span 1" />

             

                <Box gridColumn="span 2" />
                <Button sx={{mb:"80px", mr:"20px"}} type="submit" color="secondary" variant="outlined">
                    Submit
                </Button>
            
                </Box>
                </form>
                    )}
               </Formik>
                
            </Box>




        </Box>
    )
}
import {ColorModeContext, useMode} from "./themes"
import {CssBaseline, ThemeProvider} from "@mui/material"
import Topbar from "./Globals/Topbar"
import './index.css';
import Dashboard from "./scenes/Dashboard"
import Team from "./scenes/Team"
import Contacts from "./scenes/Contacts"
import Invoices from "./scenes/Invoices"
import Financials from "./scenes/Financials/"
import Bar from "./scenes/Bar"
import Form from "./scenes/Form"
import Receiving from "./scenes/Receiving"
import Profile from "./scenes/Profile"
import View from "./scenes/View"
import Login from "./scenes/Login"
import Signup from "./scenes/Signup"
import Home from "./scenes/Home"
import Privacy from "./scenes/Privacy";
import Line from "./scenes/Line"
import {AuthenticationGuard} from "./Components/authentication-guard"
import FAQ from "./scenes/FAQ"
import {PDFViewer} from '@react-pdf/renderer'
// import Calendar from "./scenes/Calendar"
import {Routes, Route, useNavigate} from "react-router-dom";
import {createContext, useState} from 'react'

import Sidebar from "./Globals/Sidebar"
//import FiancialsMainContainer from "./Components/FiancialsMainContainer"
import AsinSearchAPI from "./Utils/AsinSearchAPI"
import React from 'react';
import { getListItemAvatarUtilityClass } from '@mui/material';
import IncomingOrders from "./scenes/IncomingOrders"
import Register from "./scenes/Register";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Landing from "./scenes/Landing";
import Backdrop from "./Globals/Backdrop";
import Purchasing from "./scenes/Purchasing";
import Assignment from "./scenes/Assignment"
import Vendors from "./scenes/Vendors"
import Inventory from "./scenes/Inventory"
import WholesalePurchasing from "./scenes/WholesalePurchasing";
import PurchaseOrders from "./scenes/PurchaseOrders"
import WholesaleReceiving from "./scenes/WholesaleReceiving"



function App() {
const [list, setList] = React.useState([])
const [ theme, colorMode] = useMode();
const [loggedIn, setLoggedIn] = React.useState(false)
const [email, setEmail] = React.useState("")
const navigate = useNavigate();

  
const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();
// if(!isAuthenticated && !isLoading){ loginWithRedirect()}
if (isLoading) {
  return (
    
      <Backdrop />
  )
}

  return ( 
    <div className="app">
  <ColorModeContext.Provider value={colorMode}>
  <ThemeProvider theme={theme}>
  
      
    <CssBaseline />
      <div className = "app"></div>
      <Sidebar />
      <main className = "content"> 
        <Topbar />
        <Routes>
          <Route path="/" element ={<Landing />}/>
          {<Route path="/Dashboard" element = {<AuthenticationGuard component={Dashboard} />} />}
          {<Route path="/Team" element={<AuthenticationGuard component={Team} />} /> }
          {<Route path="/FAQ" element={ <AuthenticationGuard component={FAQ} /> } />}
          {<Route path="/Profile" element={<AuthenticationGuard component={Profile} />} /> }
          {<Route path="/Contacts" element={<AuthenticationGuard component={Contacts} /> } /> }
          {<Route path="/Invoices" element={<AuthenticationGuard component={Invoices} /> } />}
          {<Route path="/Assignment" element={<AuthenticationGuard component={Assignment} /> } />}
          {<Route path="/Form" element={<AuthenticationGuard component={Form} /> } />}
          {<Route path="/Financials" element={<AuthenticationGuard component={Financials} />} />}
          {<Route path="/IncomingOrders" element={<AuthenticationGuard component={IncomingOrders} />} />}
          {<Route path="/Receiving" element={<AuthenticationGuard component={Receiving} /> } />}
          {<Route path="/Purchasing" element={<AuthenticationGuard component={Purchasing} /> } />}
          {<Route path="/Vendors" element={<AuthenticationGuard component={Vendors} /> } />}
          {<Route path="/Privacy" element={<Privacy /> } />}
          {<Route path="/Register" element={<AuthenticationGuard component={Register} />} /> }
         { <Route path="/Login" element={<AuthenticationGuard component={Login} /> } />}
         { <Route path="/PurchaseOrders" element={<AuthenticationGuard component={PurchaseOrders} /> } />}
          {<Route path="/Bar" element={<AuthenticationGuard component={Bar} /> } />}
          {<Route path="/Inventory" element={<AuthenticationGuard component={Inventory} /> } />}
          {<Route path="/WholesalePurchasing" element={<AuthenticationGuard component={WholesalePurchasing} /> } />}
          {<Route path="/WholesaleReceiving" element={<AuthenticationGuard component={WholesaleReceiving} /> } />}
          {/* <PDFViewer width="1000" height="600" className="app" >
          {<Route path="/Bar" element={<AuthenticationGuard component={InvoicePDF} /> } />}
          </PDFViewer> */}
          {/* <Route path="/FAQ" element={<FAQ /> } /> */}
          {/* <Route path="/Geography" element={<Geography /> } /> */}
          {/* <Route path="/Calendar" element={<Calendar /> } /> */}

        </Routes>
      </main>

    </ThemeProvider>
    </ColorModeContext.Provider>
   
    </div>
  )
}

export default App;

import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"





export const IncomingOrders =  (onSuccess, onError) => {
  const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
  })
  const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();

  const getIncomingOrders = async() =>{ 
    const accessToken = await getAccessTokenSilently({
        authorizationParams: {
        },
    })
    const response = await axios('https://api.sellrconsultingsoftware.net/incoming_orders?', {
      headers:{ 
        'Content-Type': 'application/json',
    'authorization': `Bearer ${accessToken}` }
    })
    
    const responseData = response.data
    return  responseData
  }
  return useQuery({
    queryKey: ["incomingOrders"],
    queryFn: () => getIncomingOrders()
  })

}
export const BoughtIncoming = (submitToReceivedData) => {
const api = axios.create({
  baseURL: "https://api.sellrconsultingsoftware.net"
})



const postData = async (submitToReceivedData) => {
const response = await api.post("/incoming_orders", 
submitToReceivedData
)
return response
}


}
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { jwtDecode } from "jwt-decode";





export const UserInfo =  (onSuccess, onError) => {
  const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
  })
  const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();

  const apiTesting = async() =>{ 
    const accessToken = await getAccessTokenSilently({
        authorizationParams: {
        },
    })
    const response = await axios('https://api.sellrconsultingsoftware.net/api/user_info?', {
      headers:{ 
        'Content-Type': 'application/json',
    'authorization': `Bearer ${accessToken}` }
    })

    const decoded = jwtDecode(accessToken)
    console.log(decoded)
    const responseData = response.data
    return {accessToken, responseData}
  }
  return useQuery({
    queryKey: ["UserInfo"],
    queryFn: () => apiTesting()
  })

}


import { create } from "zustand";
import {StoresQuery} from "../Hooks/useStoresQuery"
import {Box, CircularProgress} from "@mui/material"




export const useHello = create((set) => {
    return {
        hello: ["Hello"],
        helloPerson: (name) => set((state) => ({hello: [...state.hello, name]})),
    };
});

export const useStore = create((set) => {
    return {
        store: ['Worth Services LLC'],
       setStore: (name) => set((state) => ({store: [name]})),
    };
});
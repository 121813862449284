import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"

   
 
    export const DefaultReportQuery = (onSuccess, onError) =>{ 

        const api = axios.create({
            baseURL: "https://api.sellrconsultingsoftware.net"
          })
        const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();
        const getDefaultReport = async() => {

            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                },
            })
            
            const response = await api.get("/report?", {
                headers:{ 
                    'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}` },
                params: {
                start_date: '2023-04-30T03:33:51.743Z',
                end_date: '2023-12-30T04:33:51.743Z',
                store_id: 'Worth Services LLC'
                }
            })
            return response.data
        }

        return useQuery({
        queryKey: ["reportDefault"],
        queryFn: () => getDefaultReport(), 
    })}
    

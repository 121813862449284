import Header from "../../Components/Header";
import {Box,Accordion,Typography, AccordionSummary, AccordionDetails,  CardActionArea,Backdrop, FormHelperText,  Modal, CardActions, TextField, Paper, Stack, Card,CardContent, CardHeader, Grid, OutlinedInput, InputLabel, Button, MenuItem, FormControl, Select, colors} from "@mui/material"
import  { useEffec, useState, useRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTheme } from "@mui/material"
import { tokens, theme } from "../../themes";
import StoreSelect from "../../Components/StoreSelect";

function AccordianItem({question, answer}){
    const idHeader = useRef(0);

    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
    return(

        <Accordion>
            <AccordionSummary
            justifyContent = "center"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id= "1"
            sx={{backgroundColor: colors.primary[500], color: colors.greenAccent[500]}}
            >
            <Typography variant = "h5" fontStyle = "oblique" fontWeight = "500">{question}</Typography>
            </AccordionSummary>
            <AccordionDetails
            sx={{backgroundColor: colors.primary[600], color:colors.grey[100]}}>
            <Typography>
                - {answer}
            </Typography>
            </AccordionDetails>
        </Accordion>
    
)}

export default function FAQ({question, answer}){
    const theme=useTheme();
    const colors = tokens(theme.palette.mode);
    return(
        <Box m="20px">
            <Header title="FAQ" subtitle="Find An Answer Here" />
            <StoreSelect />
            <Box display = "grid" m="40px" gap = "10px">
                <AccordianItem 
                question = "How do we do this?"
                answer = "With Gusto And Balls"
                />
                <AccordianItem 
                question = "Why do we do this?"
                answer = "Profit"
                />
            </Box>

        </Box>
    )
}

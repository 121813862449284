import {Box, Button,styled, Switch, Container, Collapse, Checkbox,FormControlLabel, IconButton, Backdrop, Card, Tab, Alert, Tabs, inputLabelClasses, Modal, inputClasses, CardHeader, Typography,CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid} from "@mui/material"
import { Formik, Form, Field, ErrorMessage, FieldArray, FastField} from "formik"
import useMediaQuery from "@mui/material/useMediaQuery"
import * as yup from "yup"
import { Link } from "react-router-dom";
import { tokens, theme } from "../../themes";
import { useUser, useAuth, useSession, isSignedIn } from '@clerk/clerk-react';
import Header from "../../Components/Header"
import React from 'react'
import Pdf from "../../privacy-policy.pdf"



export default function Privacy(){


    return(
        
        <Box m="20" >
            
            <Header title="Privacy Policy" subtitle = {"Your Privacy Is Important To Us" } />

          <iframe width="100%" height="2000" src={Pdf}></iframe>

        </Box>

    )
}
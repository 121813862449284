import { Box, Button, styled, InputAdornment, CircularProgress, IconButton, Backdrop, Card, Tab, Alert, Tabs, inputLabelClasses, Modal, inputClasses, CardHeader, Typography, CardContent, TextField, FormControl, Select, useTheme, MenuItem, OutlinedInput, Grid } from "@mui/material"
import { Formik, Form, Field, ErrorMessage, FieldArray, FastField } from "formik"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../Components/Header"
import { mockDataContacts } from "../../data/mockData"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Container, Row, Col, Input } from 'reactstrap'
import { tokens, theme } from "../../themes";
import React, { useEffect, useCallback } from 'react'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import BlockIcon from '@mui/icons-material/Block';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { blue } from "@mui/material/colors"
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ItemUnitsInfo from '../../Components/ItemUnitsInfo'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { whiteAlpha } from "@clerk/themes/dist/clerk-js/src/ui/foundations"

import StatBox from "../../Components/StatBox";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { IncomingOrders, BoughtIncoming } from "../../Hooks/useIncomingQuery"
import { StoresQuery } from "../../Hooks/useStoresQuery"
import { GetReceiving } from "../../Hooks/useWHReceivingQuery"
import { useDropzone } from 'react-dropzone'
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import StoreSelect from "../../Components/StoreSelect"
import { useStore } from "../../Store/Zustand"




const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})



const postIncomingData = async (submitToReceivedData) => {
    const response = await api.post("/incoming_orders",
        submitToReceivedData
    )
    return response
}

const putIncomingData = async (submitToReceivedData) => {
    const response = await api.put("/incoming_orders",
        submitToReceivedData
    )
    return response
}








const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



const Purchasing = () => {
    //States & Globals
    const [test, setTest] = React.useState([null])
    const [storeName, setStoreName] = React.useState([])
    const [clientInfo, setClientInfo] = React.useState([])
    const [newOrders, setNewOrders] = React.useState([])
    const [pastOrders, setPastOrders] = React.useState([])
    const [orders, setOrders] = React.useState(
        {}
    )
    const [count, setCount] = React.useState(0);
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width: 600px)")
    const [open, setOpen] = React.useState({
        FAQ: false,
        submitTab: false,
        updateTab: false
    })
    const [submitButtonState, setSubmitButtonState] = React.useState(null)
    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)
    })

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 15,
            bottom: params.isLastVisible ? 0 : 15,
        }
    });


    const submitIncomingData = useMutation({
        mutationFn: postIncomingData,
        onSuccess: data => {
            queryClient.invalidateQueries(["incomingOrders"], ["receivingData"])
        }
    })
    const updateIncomingData = useMutation({
        mutationFn: putIncomingData,
        onSuccess: data => {
            queryClient.invalidateQueries(["incomingOrders"], ["receivingData"])
        }
    })

    const zusStore = useStore(state => state.store)
    const receivingQuery = GetReceiving()
    const incomingOrdersQuery = IncomingOrders()
    const storesQuery = StoresQuery()

    if (incomingOrdersQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
    if (incomingOrdersQuery.isError) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
    if (storesQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
    if (storesQuery.isError) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
    if (receivingQuery.isLoading) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
    if (receivingQuery.isError) return <Box sx={{ display: 'flex' }}><CircularProgress /></Box>

    const incomingData = JSON.parse(incomingOrdersQuery.data)
    const receivingData = JSON.parse(receivingQuery.data)
    const storesDataNoKey = JSON.parse(storesQuery.data)

    console.log(storesQuery.data)




    let responseHandle = (data) => {
        let index = 0;
        for (let key in data) {
            data[key].id = index++;
        }

        return data
    }

    const storesData = responseHandle(storesDataNoKey)
    const storeNames = storesData.map((x) => x.store_id)

    //For Data Table

    //Column Button Code

    const openSubmitModal = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: colors.greenAccent[700] }}
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={
                        () => { handleOpen("submitTab"); }
                    }
                >
                    Buy
                </Button>
            </strong>
        )
    }
    const openUpdateModal = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: colors.greenAccent[700] }}
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={
                        () => { handleOpen("updateTab"); }
                    }
                >
                    Update
                </Button>
            </strong>
        )
    }


    //Columns for Incoming

    const columns = [
        { field: "line_uuid", headerName: "ID", flex: 0.5 },
        {
            field: "store_id",
            headerName: "Store ID:",
            minWidth: 150,
            align: "center",
            cellClassName: "name-column--cell"
        },
        {
            field: "PurchaseDate",
            headerName: "Order Received:",
            valueGetter: (incomingData) => incomingData.row.order_data.PurchaseDate,
            valueFormatter: (PurchaseDate) => moment(PurchaseDate.value).format("MM-DD @ HH:mm"),
            flex: .5,
            headerAlign: "left",
            align: "left"
        },
        {
            field: "ASIN",
            headerName: "ASIN:",
            valueGetter: (incomingData) => incomingData.row.line_data.ASIN,
            flex: .5
        },
        {
            field: "order_id",
            headerName: "Amazon Order ID:",
            flex: .75,
            headerAlign: "center",
            align: "center"
        },
        {
            field: "line_data.QuantityOrdered",
            headerName: "# to Buy:",
            flex: .3,
            valueGetter: (incomingData) => incomingData.row.line_data.QuantityOrdered,
            align: "center",
            headerAlign: "center"
        },
        {
            field: "line_data.title",
            headerName: "Order Item Name:",
            valueGetter: (incomingData) => incomingData.row.line_data.Title,
            headerAlign: "center",
            borderRadius: "50px",
            flex: 3
        },
        {
            field: 'col6',
            headerName: 'Buy Order:',
            backgroundColor: whiteAlpha,
            width: 150,
            renderCell: openSubmitModal,
            disableClickEventBubbling: true,
        },
    ]

    //Columns for update DataTable

    const columnsReceiving = [
        { field: "line_uuid", headerName: "ID", flex: 0.5 },
        {
            field: "store_id",
            headerName: "Store ID",
            minWidth: 150,
            align: 'center',
            cellClassName: "name-column--cell"
        },
        {
            field: "product_input_date",
            headerName: "Received",
            valueFormatter: (product_input_date) => moment(product_input_date.value).format("MM-DD @ HH:mm"),
            headerAlign: "left",
            align: "left",
            flex: 1
        },
        {
            field: "ASIN",
            headerName: "ASIN",
            headerAlign: "center",
            align: "center",
            valueGetter: (incomingData) => incomingData.row.line_data.ASIN,
            flex: 2
        },
        {
            field: "line_data.title",
            headerName: "Item name",
            valueGetter: (incomingData) => incomingData.row.line_data.Title,
            headerAlign: "center",
            width: 280
        },
        {
            field: "external_order_id",
            headerName: "Order #",
            flex: 1,
        },
        {
            field: "product_cost",
            headerName: "Cost",
            flex: 1,
            valueFormatter: (product_cost) => "$" + parseFloat(product_cost.value).toFixed(0),
            align: "center",
            headerAlign: "center"
        },
        {
            field: "line_data.QuantityOrdered",
            headerName: "Units Expected",
            headerAlign: "center",
            valueGetter: (incomingData) => incomingData.row.line_data.QuantityOrdered,
            align: "center",
            flex: 1.5
        },
        // {
        // field: "notes", 
        // headerName: "Notes", 
        // headerAlign: "center",
        // align: "left"
        // },  
        {
            field: "units_per_order_item",
            headerName: "Units/Bought",
            headerAlign: "center",
            valueFormatter: (units_per_order_item) => parseFloat(units_per_order_item.value).toFixed(0),
            align: "center",
            flex: 1,
        }, {
            field: "units_per_sell_kit",
            headerName: "Units/ASIN",
            valueFormatter: (units_per_sell_kit) => parseFloat(units_per_sell_kit.value).toFixed(0),
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "source",
            headerName: "Source",
            flex: 1,
            align: "left"
        },
        {
            field: 'col6',
            headerName: '',
            backgroundColor: whiteAlpha,
            headerAlign: "center",
            width: 100,
            renderCell: openUpdateModal,
            disableClickEventBubbling: true,
        },
    ]



    //Formik

    const initialValues = {
        line_uuid: "",
        product_uuid: "",
        storeId: "",
        orderNumber: "",
        ASIN: "",
        Quantity: "",
        itemCost: "",
        deliverDate: new Date(),
        unitsPerBought: "",
        unitsPerAsin: "",
        notes: "",
        source: "",
        sourceOrderNumber: "",
        screenshot: ""

    }


    //Handlers

    const handleFormUpdate = (values, { resetForm }) => {
        setOpen(prevOpen => ({
            ...prevOpen, updateTab: false
        }))
        const updateReceivedData = {
            "line_uuid": values.line_uuid,
            "product_uuid": values.product_uuid,
            "product_cost": values.itemCost,
            "quantity": values.Quantity,
            "source": values.source,
            "notes": values.notes,
            "external_order_id": values.sourceOrderNumber,
            "expected_delivery": "2023-11-14T21:59:08Z",
            "units_per_order_item": values.unitsPerAsin,
            "units_per_sell_kit": values.unitsPerBought

        }
            ;
        updateIncomingData.mutate(updateReceivedData)
    }

    const handleFormSubmit = (values, { resetForm }) => {
        setOpen(prevOpen => ({
            ...prevOpen, submitTab: false
        }))
        const submitToReceivedData = {
            "line_uuid": values.line_uuid,
            "product_uuid": values.product_uuid,
            "product_cost": values.itemCost,
            "quantity": values.Quantity,
            "source": values.source,
            "notes": values.notes,
            "external_order_id": values.sourceOrderNumber,
            "expected_delivery": "2023-11-14T21:59:08Z",
            "units_per_order_item": values.unitsPerBought,
            "units_per_sell_kit": values.unitsPerAsin,

        };

        submitIncomingData.mutate(submitToReceivedData)
    }

    const handleRowClick = (params) => {
        const savedValues =
        {
            storeId: params.row.store_id,
            line_uuid: params.row.line_uuid,
            product_uuid: params.row.product_uuid,
            orderNumber: params.row.order_id,
            ASIN: params.row.line_data.ASIN,
            Quantity: params.row.line_data.QuantityOrdered,
            itemCost: params.row.product_cost,
            unitsPerBought: params.row.units_per_order_item,
            notes: params.row.notes,
            source: params.row.source,
            sourceOrderNumber: params.row.external_order_id,
            unitsPerAsin: params.row.units_per_sell_kit
        }

        setOrders(prev => savedValues)
    }

    const handleStoreChange = (event) => {
        const {
            target: { value },
        } = event;
        setStoreName(
            typeof value === 'string' ? value.split(',') : value,

        )
    }

    const handleTabChange = (e, tabIndex) => {
        console.log(tabIndex);
        setCurrentTabIndex(tabIndex);
    };
    //Modals

    const handleOpen = (event) => {
        if (event === "FAQ") {
            setOpen(prevOpen => ({
                ...prevOpen, FAQ: true
            }))
        }
        if (event === "submitTab") {
            setOpen(prevOpen => ({
                ...prevOpen, submitTab: true
            }))
        }
        if (event === "updateTab") {
            setOpen(prevOpen => ({
                ...prevOpen, updateTab: true
            }))
        }
    }

    const handleClose = (event) => {
        if (event === "FAQ") {
            setOpen(prevOpen => ({
                ...prevOpen, FAQ: false
            }))
        }
        if (event === "submitTab") {
            setOpen(prevOpen => ({
                ...prevOpen, submitTab: false
            }))
        }
        if (event === "updateTab") {
            setOpen(prevOpen => ({
                ...prevOpen, updateTab: false
            }))
        }
    }


    //Styles 

    const MenuProps = {
        PaperProps: {
            style: {
                width: 200,
                maxHeight: 200,
                bgcolor: colors.blueAccent[700]

            },
        },
    };

    function getStyles(storeNames, storeName, theme) {
        return {
            fontWeight:
                storeName.indexOf(storeNames) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    const TextFields = styled(TextField)(`
  .${inputClasses.root} {
  font-size: 10px;
  color: ${colors.greenAccent[500]}
  }
  .${inputLabelClasses.root} {
  font-size: 13px;
  font-weight: bold;
  color: ${colors.grey[100]};
  &.${inputLabelClasses.focused} {
      color: ${colors.grey[700]};
  }
 
  }
  `);

    return (
        <Box m="20px">

            <Header title="PURCHASING" subtitle="Product Buying" />
            <StoreSelect />
            <Box display="flex" sx={{ justifyContent: 'space-between' }} width="100%" backgroundColor={colors.primary[500]}>
                <Box display="flex" width="40%" mr="2" backgroundColor={colors.primary[500]}>
                    <Formik
                        onSubmit={handleFormSubmit}


                        initialValues={orders || initialValues}
                        // validationSchema={userSchema}
                        enableReinitialize
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit} >
                                <Box

                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}>


                                    <TextFields
                                        fullWidth
                                        variant="outlined"

                                        type="text"
                                        align="center"
                                        label="Store"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={zusStore[0]}
                                        name="storeId"
                                        disabled
                                        error={!!touched.storeId && !!errors.storeId}
                                        helperText={touched.storeId && errors.storeId}
                                        sx={{
                                            '& .MuiTextField-root': { color: 'black' }, ml: 5, mt: 5,
                                            gridColumn: "span 4",
                                            input: { cursor: "not-allowed" },
                                            backgroundColor: colors.primary[500],
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: `${colors.grey[100]}`,
                                            },
                                        }}
                                    />
                                    {/* <TextFields 
                fullWidth
                variant="outlined"
                type="text"
                label="Amazon Order Number"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
                value = {values.orderNumber}
                name="orderNumber"
                error = {!!touched.orderNumber && !!errors.orderNumber}
                helperText ={touched.orderNumber && errors.orderNumber}
                sx={{
                    input: {cursor: 'not-allowed'},
                    gridColumn: "span 4",
                    backgroundColor: colors.primary[500], ml: 5, 
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: `${colors.grey[100]}`,
                      },
                }}
                /> */}
                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="ASIN"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.ASIN}
                                        name="ASIN"
                                        error={!!touched.ASIN && !!errors.ASIN}
                                        helperText={touched.ASIN && errors.ASIN}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="Products Purchased From Source"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.Quantity}
                                        name="Quantity"
                                        error={!!touched.Quantity && !!errors.Quantity}
                                        helperText={touched.Quantity && errors.Quantity}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="outlined"

                                        type="text"
                                        label="Item Cost"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.itemCost}
                                        name="itemCost"
                                        error={!!touched.itemCost && !!errors.itemCost}
                                        helperText={touched.itemCost && errors.itemCost}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            closeOnSelect={true}
                                            values={values.deliverDate}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="deliverDate"
                                            error={!!touched.deliverDate && !!errors.deliverDate}
                                            helperText={touched.deliverDate && errors.deliverDate}


                                            sx={{
                                                backgroundColor: colors.primary[500],
                                                color: colors.grey[100], ml: 5,
                                                gridColumn: "span 2"
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="Units Per Bought"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.unitsPerBought}
                                        name="unitsPerBought"
                                        error={!!touched.unitsPerBought && !!errors.unitsPerBought}
                                        helperText={touched.unitsPerBought && errors.unitsPerBought}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={() => { handleOpen("FAQ"); }}>
                                                    <QuestionMarkIcon
                                                    />




                                                    <Modal
                                                        open={open.FAQ}
                                                        onClose={() => { handleClose("FAQ") }}
                                                        aria-labelledby="Profit Modal"
                                                        aria-describedby="transition-modal-description"
                                                        slots={{ backdrop: Backdrop }}
                                                        slotProps={{
                                                            backdrop: {
                                                                timeout: 500,
                                                            },
                                                        }}
                                                    >
                                                        <Box sx={modalStyle} >
                                                            <ItemUnitsInfo />
                                                        </Box>
                                                    </ Modal>

                                                </ IconButton>

                                            ),
                                        }}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />

                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="Units Needed For ASIN"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.unitsPerAsin}
                                        name="unitsPerAsin"
                                        error={!!touched.unitsPerAsin && !!errors.unitsPerAsin}
                                        helperText={touched.unitsPerAsin && errors.unitsPerAsin}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={() => { handleOpen("FAQ"); }}>
                                                    <QuestionMarkIcon />

                                                    <Modal
                                                        open={open.FAQ}
                                                        onClose={() => { handleClose("FAQ") }}
                                                        aria-labelledby="Profit Modal"
                                                        aria-describedby="transition-modal-description"
                                                        slots={{ backdrop: Backdrop }}
                                                        slotProps={{
                                                            backdrop: {
                                                                timeout: 500,
                                                            },
                                                        }}
                                                    >
                                                        <Box sx={modalStyle} >
                                                            <ItemUnitsInfo />
                                                        </Box>
                                                    </ Modal>

                                                </ IconButton>

                                            ),
                                        }}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="Bought From?"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.source}
                                        name="source"
                                        error={!!touched.source && !!errors.source}
                                        helperText={touched.source && errors.source}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="Order Number from Source"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.sourceOrderNumber}
                                        name="sourceOrderNumber"
                                        error={!!touched.sourceOrderNumber && !!errors.sourceOrderNumber}
                                        helperText={touched.sourceOrderNumber && errors.sourceOrderNumber}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 2",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        label="Notes:"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.notes}
                                        name="notes"
                                        error={!!touched.notes && !!errors.notes}
                                        helperText={touched.notes && errors.notes}
                                        sx={{
                                            input: { color: colors.grey[100] }, ml: 5,
                                            gridColumn: "span 4",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        type="text"

                                        onChange={handleChange}
                                        value={values.line_uuid}
                                        name="line_uuid"
                                        error={!!touched.line_uuid && !!errors.line_uuid}
                                        helperText={touched.line_uuid && errors.line_uuid}
                                        sx={{
                                            input: { cursor: 'not-allowed' },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                                display: "none"
                                            },

                                            gridColumn: "span 2", ml: 5,
                                            display: "none",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    <TextFields
                                        type="text"

                                        onChange={handleChange}
                                        value={values.product_uuid}
                                        name="product_uuid"
                                        error={!!touched.product_uuid && !!errors.product_uuid}
                                        helperText={touched.product_uuid && errors.product_uuid}
                                        sx={{
                                            input: { cursor: 'not-allowed' },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                                display: "none"
                                            },

                                            gridColumn: "span 2", ml: 5,
                                            display: "none",
                                            backgroundColor: colors.primary[500]
                                        }}
                                    />
                                    {(formProps) => (
                                        <Form>
                                            <Input
                                                type="file"
                                                name="screenshot"
                                                onChange={(event) =>
                                                    formProps.setFieldValue('screenshot', event.target.files[0])} />
                                        </Form>

                                    )}

                                </Box>
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button onSubmit={handleFormSubmit} onClick={() => setSubmitButtonState("submit")} type="submit" color="secondary" variant="contained" startIcon={< SendOutlinedIcon />}>
                                        Complete Order
                                    </Button>
                                </Box>

                            </form>
                        )}
                    </Formik>
                </Box>
                <Box
                    ml="10px"
                    mr="10px"
                    height="75vh"
                    width="50%"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: ".1",
                            borderTop: ".1"
                        },


                        "& .name-column--cell": {
                            color: colors.greenAccent[300]
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.primary[500],
                            borderBottom: "none"
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[500]
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.primary[500],
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`
                        },


                    }}
                >
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'PurchaseDate', sort: 'asc' }],
                            },
                            columns: {
                                columnVisibilityModel: {
                                    line_uuid: false,
                                    product_uuid: false,
                                    store_id: false,
                                    product_input_date: false,
                                    external_order_id: false,
                                    product_cost: false,
                                    units_per_order_item: false,
                                    units_per_sell_kit: false,
                                    source: false,


                                }
                            }
                        }}
                        getRowHeight={() => 'auto'}
                        disableDensitySelector
                        getRowSpacing={getRowSpacing}

                        rows={receivingData}
                        getRowId={(row) => row.line_uuid}
                        columns={columnsReceiving}
                        // components={{Toolbar: GridToolbar}}
                        onRowClick={handleRowClick}
                        GridLinesVisibility="None"
                        sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important"
                            },
                            '& .Mui-selected': {
                                backgroundColor: `${colors.greenAccent[800]} !important`
                            }
                            ,
                        }}


                    />
                </ Box>
            </Box>
        </Box>
    )
}
export default Purchasing
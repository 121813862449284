import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"





export const GetVendorQuery =  (asin) => {
  const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
  })
  const { loginWithRedirect,isAuthenticated, logout, getAccessTokenSilently, isLoading,user } = useAuth0();

  const getVendor = async() =>{ 
    const accessToken = await getAccessTokenSilently({
        authorizationParams: {
        },
    })
    const response = await axios('https://api.sellrconsultingsoftware.net/vendors?', {
        
      headers:{ 
        'Content-Type': 'application/json',
    'authorization': `Bearer ${accessToken}` },
    params: {
        asin: asin,
        }
    })
    
    const responseData = response.data
    return  response
  }
  return useQuery({
    queryKey: ["vendors"],
    queryFn: () => getVendor(),
  })

}
import { Box, Button, DialogTitle, Dialog, Select, DialogContent, MenuItem, DialogActions, DialogContentText, Snackbar, Autocomplete, Input, IconButton, InputLabel, ListItem, ListItemText, Modal, Backdrop, LinearProgress, Alert, List, Tooltip, Divider, Typography, TextField, useTheme } from "@mui/material"
import { Formik } from "formik"
import { useState, useRef, Fragment, useCallback } from "react"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../Components/Header"
import { tokens, theme } from "../../themes";
import { GetVendorQuery } from "../../Hooks/useVendorQuery"
import axios from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import UnitListItem from "../../Components/UnitListItem"
import AWS from 'aws-sdk';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddSkuForm from "../../Components/AddSkuForm"
import { WarehouseSkuQuery } from "../../Hooks/useWarehouseSkusQuery"
import VendorForm from "../../Components/VendorForm"
import { useDropzone } from 'react-dropzone'
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ConfirmDialogue from "../../Components/ConfirmDialogue"
import SuccessAlert from "../../Components/SuccessAlert"
import { WarehousePOs } from "../../Hooks/useWarehousePoQuery"




const initialValues = {
    cost_per_quantity: "",
    quantity: "",
    location: "",
    units_per_quantity: "",
    notes: "",
    skuInput: null
}

const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})

const deleteVendor = async (submitToPackedData) => {
    const response = await api.delete(`/vendors`, {data: {"vendor_id" : submitToPackedData}}
    
        
    )
    return response
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    minWidth: "60%",
    height: 'auto',
    maxHeight: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "scroll"
};


const TextFields = ({ variant, type, label, onBlur, onChange, value, name, error, helperText, tooltip, sx }) => {
    return (<Tooltip title={tooltip}>
        <TextField
            fullWidth
            variant={variant}
            type={type}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            name={name}
            error={error}
            helperText={helperText}
            sx={sx}

        />
    </Tooltip>
    )
}


const postNewVendor = async (submitToReceivedData) => {

    const response = await api.post("/vendors",
        submitToReceivedData
    )
    return response
}

const postPurchaseOrder = async (submitToReceivedData) => {

    const response = await api.post("/warehouse_purchase_orders?",
        submitToReceivedData
    )
    return response
}



const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const userSchema = yup.object().shape({
    vendorName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    contact: yup.
        string().
        matches(phoneRegExp, "Phone Number is Not Valid")
        .required("required"),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    website: yup.string(),
    repNumber: yup.string().matches(phoneRegExp, "Phone Number is Not Valid"),
    repEmail: yup.string().email("invalid email"),
    note: yup.string()
})

const WholesalePurchasing = ({ className }) => {
    const [value, setValue] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [selectedIndexVendor, setSelectedIndexVendor] = useState(0);
    const [files, setFiles] = useState([])
    const [alert, setAlert] = useState(false);
    const [units, setUnits] = useState([])
    const [count, setCount] = useState(0)
    const [progress, setProgress] = useState(null)
    const [file, setFile] = useState("")
    const [orderList, setOrderList] = useState([])
    const [poDetails, setPoDetails] = useState([])
    const [open, setOpen] = useState({
        addProduct: false,
        addVendor: false,
        submitConfirmation: false,
        deleteConfirmation: false,
        deleteVendorConfirmation: false,
        successAlert: false

    })

    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width: 600px)")
    
    const deleteSelectedVendor = useMutation({
        mutationFn: deleteVendor,
        onSuccess: data => {
            queryClient.invalidateQueries(["vendors"])
        }
    })
    const warehouseSkuQuery = WarehouseSkuQuery()
    const vendorQuery = GetVendorQuery()

    const submitNewVendor = useMutation({
        mutationFn: postNewVendor,
        onSuccess: data => {
            queryClient.invalidateQueries(["vendors"])
        }
    })
    const submitPurchaseOrder = useMutation({
        mutationFn: postPurchaseOrder,
        onSuccess: data => {
            queryClient.invalidateQueries(["vendors"]);
            setOpen(prevOpen => ({
                ...prevOpen, successAlert: true
            }));
            setOpen(prevOpen => ({
                ...prevOpen, submitConfirmation: false
            }))
            setOrderList([])
        }
    })


    const resetList = []

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length) {
            setFiles(previousFiles => [
                ...previousFiles,
                acceptedFiles.map(file =>
                    Object.assign(file, { preview: URL.createObjectURL(file) }))
            ])
        }
    },
        [])

    const removeFile = (name) => {
        setFiles(files => files.filter(file => file.name !== name))
    }
    const reader = new FileReader()

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 15,
            bottom: params.isLastVisible ? 0 : 15,
        }
    });

    if (vendorQuery.isLoading) return console.log('waiting')
    if (vendorQuery.isError) return console.log('failed')
    if (warehouseSkuQuery.isLoading) return console.log('waiting')
    if (warehouseSkuQuery.isError) return console.log('failed')

    const vendorData = vendorQuery.data.data
    const skuData = warehouseSkuQuery.data.data


    const skuFilter = skuData?.filter((sku) => {
        let vendorKeyName = sku.vendors.map(item => item.key_name)
        if(vendorData[selectedIndexVendor] === undefined){
            return 
        }
        if (vendorKeyName.includes(vendorData[selectedIndexVendor].key_name)) {
            return sku
        }
    })



    let skuList = ""
    skuList = skuFilter.map(item => item.sku_info.name)


    const handleClick = () => {
        setValue(skuList[0]);
        setInputValue(skuList[0]);
    };



    const handleListItemClick = (event, index) => {
        setSelectedIndexVendor(index);

    };


    let vendorList = vendorData?.map(function (vendor, i) {
if(vendorData === undefined) {
    return "No Vendors. Please Add Vendor."
}

        return <Fragment key={i} ><UnitListItem primary={i + 1 + ". " + vendor.vendor_name} secondary=" " selected={selectedIndexVendor === i} onClick={(event) => handleListItemClick(event, i)} />
         <Divider variant="fullWidth" /> </Fragment>;
    })


    const uploadFile = async () => {
        const S3_BUCKET = "sellrimages";
        const REGION = "us-east-1";

        AWS.config.update({
            accessKeyId: "AKIA3MRUS4L6L2SLF5XV",
            secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: file.name,
            Body: file,
        };

        var upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {

                setProgress(parseInt((evt.loaded * 100) / evt.total))
            })
            .promise();

        await upload.then((err, data) => {
            console.log(err);
            // alert("Successfully Uploaded File")
            setAlert(true)

            setProgress(null)
        });
    };


    const columns = [
        {
            field: "warehouse_sku_uuid",
            headerName: "UUID",
            minWidth: 150,
            align: "center",



        },
        {
            field: "productName",
            headerName: "Product Name",
            minWidth: 150,
            align: "center",
            headerAlign: "center",

        },
        {
            field: "cost_per_quantity",
            headerName: "Unit/Price",
            valueGetter: (params) => "$ " + params.value,
            minWidth: 50,
            align: "center",
            headerAlign: "center",

        },
        {
            field: "quantity",
            headerName: "Quantity",
            minWidth: 50,
            align: "center",
            headerAlign: "center",

        },
        {
            field: "Total",
            headerName: "Total Price",
            width: 100,
            valueGetter: (params) => "$ " + (params.row.cost_per_quantity * params.row.quantity)
        }
    ]

    const handleAddItem = (values, { resetForm, setFieldValue }) => {

        let sku = skuFilter.filter((sku) => {
            return sku.sku_info.name === values.skuInput


        })
        console.log(sku[0].sku_info.name)

        const newProduct = {
            warehouse_sku_uuid: sku[0].warehouse_sku_uuid,
            warehouse_sku: sku[0].warehouse_sku,
            productName: sku[0].sku_info.name,
            cost_per_quantity: values.cost_per_quantity,
            units_per_quantity: values.units_per_quantity,
            quantity: values.quantity,
            notes: values.notes,
            grandTotal: parseFloat(values.quantity) * parseFloat(values.cost_per_quantity),
            invoicePicture: file

        }
        console.log(newProduct)
        // const { warehouse_sku_uuid } = newProduct.skuInfo[0]
        let indexLocation = skuList.indexOf(values.skuInput)
        setOrderList([...orderList, newProduct])
        resetForm({ values: initialValues })
        // setFieldValue("skuInput", initialValues.skuInput)

    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert(false);
    };

    const handleOpen = (event) => {
        if (event === "addProduct") {
            setOpen(prevOpen => ({
                ...prevOpen, addProduct: true
            }))
        }
        if (event === "addVendor") {
            setOpen(prevOpen => ({
                ...prevOpen, addVendor: true
            }))
        }
        if (event === "submitConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, submitConfirmation: true
            }))
        }
        if (event === "deleteConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, deleteConfirmation: true
            }))
        }
        if (event === "successAlert") {
            setOpen(prevOpen => ({
                ...prevOpen, successAlert: true
            }))
        }
        if (event === "deleteVendorConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, deleteVendorConfirmation: true
            }))
        }

    }

    const handleClose = (event) => {
        if (event === "addProduct") {
            setOpen(prevOpen => ({
                ...prevOpen, addProduct: false
            }))
        }
        if (event === "addVendor") {
            setOpen(prevOpen => ({
                ...prevOpen, addVendor: false
            }))
        }
        if (event === "submitConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, submitConfirmation: false
            }))
        }
        if (event === "deleteConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, deleteConfirmation: false
            }))
        }
        if (event === "successAlert") {
            setOpen(prevOpen => ({
                ...prevOpen, successAlert: false
            }))
        }
        if (event === "deleteVendorConfirmation") {
            setOpen(prevOpen => ({
                ...prevOpen, deleteVendorConfirmation: false
            }))
        }

    }

    const currentVendor = !vendorData[selectedIndexVendor] ? null: vendorData[selectedIndexVendor].vendor_name
    const handleDeleteConfirmation = () => {
        setOrderList(resetList)
        setOpen(prevOpen => ({
            ...prevOpen, deleteConfirmation: false
        }))
        setOpen(prevOpen => ({
            ...prevOpen, successAlert: true
        }))
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        setFile(file)
    }

  

    const handleSubmitPO = () => {
        const currentDate = new Date().toISOString();


        const grandTotalsArray = orderList.map((orderItem) => orderItem.grandTotal)

        let grandTotalOfPo = grandTotalsArray.reduce(function (accumulator, curValue) {

            return accumulator + curValue

        }, 0)

        const submitToReceivedData = {
            vendor_id: vendorData[selectedIndexVendor].vendor_id,
            expected_delivery: currentDate,
            grand_total: grandTotalOfPo,
            shipping_costs: 50,
            discounts: {},
            other_costs: {},
            purchase_order_number: 4,
            products: orderList

        };

        submitPurchaseOrder.mutate(submitToReceivedData)


    }
    const handleDeleteVendor = () =>{
        if (vendorData[selectedIndexVendor] === undefined ){
            console.log("fuck")
        }
        else{
        deleteSelectedVendor.mutate(vendorData[selectedIndexVendor].vendor_id)
        setOpen(prevOpen => ({
            ...prevOpen, deleteVendorConfirmation: false
        }))
        }
    }
    return (
        <Box m="20px">
            <Header title="Wholesale Purchase Orders" subtitle="Add your PO Here" />
            <Box display="flex" gap="20px" >
                
                <Button onClick={() => { handleOpen("addProduct") }} color="secondary" variant="outlined"  > Add SKU </Button>
                {orderList.length === 0 && <Button onClick={() => { handleOpen("addVendor") }} color="secondary" variant="outlined"  > Add Vendor </Button>}
                {orderList.length === 0 && <Button onClick={() => { handleOpen("deleteVendorConfirmation") }} color="secondary" variant = "outlined"> Delete Vendor: {currentVendor ? currentVendor: "No Vendor Selected"}  </Button>}
                
                {orderList.length > 0 && <Button onClick={() => { handleOpen("submitConfirmation") }} color="secondary" variant="outlined" > Submit Purchase Order </Button>
                }
                {<ConfirmDialogue
                    open={open.submitConfirmation}
                    onClose={() => { handleClose("submitConfirmation") }}
                    title={"Confirm Purchase Order"}
                    body="Are You Sure You Want to Submit This Purchase Order?"
                    onClick={handleSubmitPO}
                    buttonText="Confirm"
                />
                }
                {<ConfirmDialogue
                    open={open.deleteVendorConfirmation}
                    onClose={() => { handleClose("deleteVendorConfirmation") }}
                    title={"Confirm Delete Vendor"}
                    body="Are You Sure You Want to Delete This Vendor??"
                    onClick={handleDeleteVendor}
                    buttonText="Confirm"
                />
                }
                {orderList.length > 0 && <Button onClick={() => { handleOpen("deleteConfirmation") }} color="secondary" variant="outlined"  > Delete List </Button>}
                {<ConfirmDialogue
                    open={open.deleteConfirmation}
                    onClose={() => { handleClose("deleteConfirmation") }}
                    title={"Confirm Delete Purchase Order"}
                    body="Are You Sure You Want to Delete All Items In Purchase Order?"
                    onClick={handleDeleteConfirmation}
                    buttonText="delete"
                />
                }
                <SuccessAlert open={open.successAlert} position = "top" onClose={() => { handleClose("successAlert") }} icon={<CheckIcon fontSize="inherit" />} message="Successfully Deleted Items" />
            </Box>
            <Modal
                open={open.addProduct}
                onClose={() => { handleClose("addProduct") }}
                aria-labelledby="Profit Modal"
                aria-describedby="transition-modal-notes"
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}

            >
                <Box sx={modalStyle} display="flex" justifyContent="center" >

                    <AddSkuForm vendor={vendorData[selectedIndexVendor]} />
                </Box>
            </ Modal>
            <Modal
                open={open.addVendor}
                onClose={() => { handleClose("addVendor") }}
                aria-labelledby="Profit Modal"
                aria-describedby="transition-modal-notes"
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Box sx={modalStyle} >
                    <Header title="Add A Vendor" subtitle="SKUs Must Have A Vendor To Be Created" />
                    <VendorForm />
                </Box>
            </ Modal>
            <Box display="flex" padding="20px" justifyContent="space-between" >
                {orderList.length === 0 ? <Box width='40%' gap="50px">
                    <List subheader="Vendors" padding="20px">

                        {vendorList}
                    </List>
                </Box> :
                    <Box
                        m="40px 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: ".1",
                                borderTop: ".1"
                            },


                            "& .name-column--cell": {
                                color: colors.greenAccent[300]
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.primary[500],
                                borderBottom: "none",
                                textDecoration: "underline"
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[500],
                                fontSize: "20px"

                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.primary[500],
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`
                            },


                        }}
                    >

                        <Typography color="secondary" variant="h3"> Vendor: {currentVendor}  </Typography>
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'PurchaseDate', sort: 'asc' }],
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns id and lastname.
                                        // Other columns will remain visible

                                        warehouse_sku_uuid: false,


                                    }
                                },

                            }}

                            showCellRightBorder={true}
                            getRowHeight={() => 'auto'}
                            disableDensitySelector
                            rows={orderList}
                            getRowSpacing={getRowSpacing}
                            getRowId={(row) => row.warehouse_sku_uuid}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}

                            GridLinesVisibility="None"
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important"
                                },
                                '& .Mui-selected': {
                                    backgroundColor: `${colors.greenAccent[800]} !important`
                                }
                                ,
                            }}


                        />
                    </ Box>
                }
                <Formik
                    onSubmit={handleAddItem}
                    initialValues={initialValues}

                >
                    {({ values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"

                                gap="20px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}>
                                {/* <Select
                                    value={value.skuInput}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="skuInput"
                                    error={!!touched.skuInput && !!errors.skuInput}
                                    helperText={touched.skuInput && errors.skuInput}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                /> */}
                                {/* <Autocomplete
                                    options={skuList}
                                    name="skuInput"
                                    inputValue={value.skuInput}

                                    onChange={(e, value) => {

                                        setFieldValue(
                                            "skuInput",
                                            value !== null ? value : initialValues.skuInput
                                        );
                                    }}
                                    sx={{ gridColumn: "span 4" }}
                                    renderInput={(params) => <TextField name="skuInput" {...params} />} */}
                                {/* /> */}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    select
                                    label="Select Product"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.skuInput}
                                    name="skuInput"
                                    error={!!touched.skuInput && !!errors.skuInput}
                                    helperText={touched.skuInput && errors.skuInput}
                                    sx={{
                                        backgroundColor: colors.primary[900],
                                        input: { color: colors.grey[900] },
                                        gridColumn: "span 4"
                                    }}
                                >

                                    {skuFilter.map(item => (
                                        <MenuItem key={item.sku_info.name} value={item.sku_info.name}>{item.sku_info.name}</MenuItem>
                                    ))}
                                </TextField>
                                {/* <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Invoice #"
                                    value={values.poInvoice}
                                    name="poInvoice"
                                    error={!!touched.poInvoice && !!errors.poInvoice}
                                    helperText={touched.poInvoice && errors.poInvoice}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                /> */}
                                {/* <Typography sx={{ gridColumn: "span 4" }} varaint="h2"> <u>Product Information</u></Typography>
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    tooltip="This is OUR internal SKU"
                                    type="text"
                                    label="Warehouse SKU"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.warehouseSku}
                                    name="warehouseSku"
                                    error={!!touched.warehouseSku && !!errors.warehouseSku}
                                    helperText={touched.warehouseSku && errors.warehouseSku}
                                    sx={{
                                        backgroundColor: colors.primary[900],
                                        input: { color: colors.grey[900] },
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    tooltip="This is the SKU our Vendor uses"
                                    type="text"
                                    label="Vendor SKU"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.vendorSku}
                                    name="vendorSku"
                                    error={!!touched.vendorSku && !!errors.vendorSku}
                                    helperText={touched.vendorSku && errors.vendorSku}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    tooltip="If product is available then scan barcode"
                                    type="text"
                                    label="Barcode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.barcode}
                                    name="barcode"
                                    error={!!touched.barcode && !!errors.barcode}
                                    helperText={touched.barcode && errors.barcode}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Product Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={!!touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="UPC"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.upc}
                                    name="state"
                                    error={!!touched.upc && !!errors.upc}
                                    helperText={touched.upc && errors.upc}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="EAN"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ean}
                                    name="ean"
                                    error={!!touched.ean && !!errors.ean}
                                    helperText={touched.ean && errors.ean}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Manufacturer"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.manufacturer}
                                    name="manufacturer"
                                    error={!!touched.manufacturer && !!errors.manufacturer}
                                    helperText={touched.manufacturer && errors.manufacturer}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <Box display="grid" width="100%" gap="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gridColumn="span 4">
                                    <Typography sx={{ gridColumn: "span 4" }}> <u>Product Size</u></Typography>
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Height"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.height}
                                        name="height"
                                        error={!!touched.height && !!errors.height}
                                        helperText={touched.height && errors.height}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Width"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.width}
                                        name="width"
                                        error={!!touched.width && !!errors.width}
                                        helperText={touched.width && errors.width}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Length"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.length}
                                        name="length"
                                        error={!!touched.length && !!errors.length}
                                        helperText={touched.length && errors.length}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Weight"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.weight}
                                        name="weight"
                                        error={!!touched.weight && !!errors.weight}
                                        helperText={touched.weight && errors.weight}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                </Box> */}
                                <Typography sx={{ gridColumn: "span 4" }}> <u>Order Details: </u></Typography>
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Cost per Quantity"
                                    value={values.cost_per_quantity}
                                    name="cost_per_quantity"
                                    error={!!touched.cost_per_quantity && !!errors.cost_per_quantity}
                                    helperText={touched.cost_per_quantity && errors.cost_per_quantity}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                {/* <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Shipping Cost"
                                    value={values.shipping}
                                    name="shipping"
                                    error={!!touched.shipping && !!errors.shipping}
                                    helperText={touched.shipping && errors.shipping}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                /> */}
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Quantity"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.quantity}
                                    name="quantity"
                                    error={!!touched.quantity && !!errors.quantity}
                                    helperText={touched.quantity && errors.quantity}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Units Per Quantity"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.units_per_quantity}
                                    name="units_per_quantity"
                                    error={!!touched.units_per_quantity && !!errors.units_per_quantity}
                                    helperText={touched.units_per_quantity && errors.units_per_quantity}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />


                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="notes"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.notes}
                                    name="notes"
                                    error={!!touched.notes && !!errors.notes}
                                    helperText={touched.notes && errors.notes}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />


                                <InputLabel sx={{ gridColumn: "span 1", ml: "10%", my: "auto" }}><Typography variant="h5" color="white">Upload Invoice: </Typography> </InputLabel>

                                <TextField type="file" onChange={handleFileChange} variant="outlined" sx={{ gridColumn: "span 2" }} />

                                {/* <Box {...getRootProps({
                                    className: { className }
                                })} sx={{ gridColumn: "span 2", backgroundColor: colors.primary[500]}}>
                                    <Input {...getInputProps()} />
                                  <Typography sx={{margin:"20px"}}color="white"> <b>Drop Files Here</b></Typography>
                                    
                                        {files.map(file => (
                                            <li style={{color:"white"}}> {files[0][0].name} 
                                            <br />
                                            <IconButton onClick={() => removeFile(file.name)}><DeleteIcon /> </IconButton>
                                            <img src = {file[0].preview} alt = "" width={100} height={100} />
                                            
                                            </li>
                                        ))}
                                    
                                </Box> */}
                                <Box />
                                {progress && <LinearProgress sx={{ gridColumn: "span 4" }} color="secondary" variant="determinate" value={progress} />}


                                <Snackbar open={alert} onClose={handleAlertClose} autoHideDuration={1000} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                                    <Alert icon={<CheckIcon fontSize="inherit" />} sx={{ width: '100%' }} severity="success">Successfully Uploaded Invoice</Alert></Snackbar>


                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type="submit" color="secondary" variant="outlined" >
                                        Add Product To PO
                                    </Button>
                                </Box>

                            </Box>
                        </form>
                    )}
                </Formik>

            </Box>
        </Box>
    )
}
export default WholesalePurchasing
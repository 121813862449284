import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'


export default function Backdrops(){
    const [open, setOpen] = React.useState(true);
 
  
    return(
        <Box>

<Backdrop
  sx={{ color: 'black', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
 
>
  <CircularProgress color="inherit" />
</Backdrop>
</Box>

    )
}



import { Box, Button, Snackbar, LinearProgress, Alert, List, Tooltip, Divider, Typography, TextField, useTheme } from "@mui/material"
import { Formik } from "formik"
import { useState, useRef, Fragment } from "react"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../Components/Header"
import { tokens, theme } from "../themes";
import { GetVendorQuery } from "../Hooks/useVendorQuery"
import axios from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import UnitListItem from "../Components/UnitListItem"
import AWS from 'aws-sdk';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { WarehouseSkuQuery } from "../Hooks/useWarehouseSkusQuery"
import SuccessAlert from "../Components/SuccessAlert"


const initialValues = {
    warehouseSku: "",
    vendorSku: "",
    barcode: "",
    name: "",
    upc: "",
    ean: "",
    manufacturer: "",
    height: "",
    width: "",
    length: "",
    weight: "",
    pricePerUnit: "",
    quantity: "",
    location: "",
    lastCycleCount: "",
    description: "",
    poInvoice: null,
}

const api = axios.create({
    baseURL: "https://api.sellrconsultingsoftware.net"
})



const TextFields = ({ variant, type, label, onBlur, onChange, value, name, error, helperText, tooltip, sx }) => {
    return (<Tooltip title={tooltip}>
        <TextField
            fullWidth
            variant={variant}
            type={type}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            name={name}
            error={error}
            helperText={helperText}
            sx={sx}

        />
    </Tooltip>
    )
}


const postNewVendor = async (submitToReceivedData) => {

    const response = await api.post("/vendors",
        submitToReceivedData
    )
    return response
}
const postNewSku = async (submitToReceivedData) => {

    const response = await api.post("/warehouse_skus",
        submitToReceivedData
    )
    return response
}



const AddSkuForm = ({ vendor }) => {
    const [selectedIndexVendor, setSelectedIndexVendor] = useState(0);

    const [alert, setAlert] = useState(false);
    const [progress, setProgress] = useState(null)
    const [file, setFile] = useState("")
    const [orderList, setOrderList] = useState("")

    const queryClient = useQueryClient()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width: 600px)")
    const [open, setOpen] = useState({
        successAlert: false,
    })

    const warehouseSkuQuery = WarehouseSkuQuery()
    const vendorQuery = GetVendorQuery()


    const submitNewSku = useMutation({
        mutationFn: postNewSku,
        onSuccess: data => {
            queryClient.invalidateQueries(["vendors"], ["warehouseSkus"])
        }
    })


    if (vendorQuery.isLoading) return console.log('waiting')
    if (vendorQuery.isError) return console.log('failed')
    if (warehouseSkuQuery.isLoading) return console.log('waiting')
    if (warehouseSkuQuery.isError) return console.log('failed')

    const vendorData = vendorQuery.data.data


    const handleListItemClick = (event, index) => {
        setSelectedIndexVendor(index);
        console.log(vendorData[index])
    };


    let vendorList = vendorData?.map(function (vendor, i) {

        return <Fragment key={i} ><UnitListItem primary={"- " + vendor.vendor_name} secondary={vendor.vendor_information.contact} selected={selectedIndexVendor === i} onClick={(event) => handleListItemClick(event, i)} /> <Divider variant="fullWidth" /> </Fragment>;
    })

    const handleAddItem = (values,{resetForm}) => {
        console.log(values)
        const newSku = {
            warehouse_sku: values.warehouseSku,
            vendors: [{
                key_name: vendor.key_name,
                vendor_sku: values.vendorSku,
            },
            
        
        ],
            sku_info: {
                name: values.name,
                upc: values.upc,
                ean: values.ean,
                manufacturer: values.manufacturer,
                height: values.height,
                width: values.width,
                length: values.length,
                weight: values.weight,
                location: values.location,
                description: values.description
            }

        }

        submitNewSku.mutate(newSku)
        resetForm({ values: initialValues })
        setOpen(prev => ({
            ...prev, successAlert: true
        }))
    }
    const handleOpen = (event) => {
        if (event === "successAlert") {
            setOpen(prevOpen => ({
                ...prevOpen, successAlert: true
            }))
        }
    }
    const handleClose = (event) => {
        if (event === "successAlert") {
            setOpen(prevOpen => ({
                ...prevOpen, successAlert: false
            }))
        }
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0]
        setFile(file)
    }
    return (
        <Box m="20px">
            <Header title="Add A SKU" subtitle={"Vendor Selected: " + vendor.vendor_name} />
            <SuccessAlert open={open.successAlert} position = "bottom" onClose={() => { handleClose("successAlert") }} icon={<CheckIcon fontSize="inherit" />} message="Successfully Added SKU" />

            <Box display="flex" padding="20px" justifyContent="space-between" >
                {!vendor && <Box width='40%' gap="50px">
                    <List subheader="Vendors" padding="20px">

                        {vendorList}
                    </List>
                </Box>}
                <Formik
                    onSubmit={handleAddItem}
                    initialValues={initialValues}

                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"

                                gap="20px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}>
                                <Typography sx={{ gridColumn: "span 4" }} varaint="h2"> <u>Product Information</u></Typography>
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    tooltip="This is OUR internal SKU"
                                    type="text"
                                    label="Warehouse SKU"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.warehouseSku}
                                    name="warehouseSku"
                                    error={!!touched.warehouseSku && !!errors.warehouseSku}
                                    helperText={touched.warehouseSku && errors.warehouseSku}
                                    sx={{
                                        backgroundColor: colors.primary[900],
                                        input: { color: colors.grey[100] },
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    tooltip="This is the SKU our Vendor uses"
                                    type="text"
                                    label="Vendor SKU"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.vendorSku}
                                    name="vendorSku"
                                    error={!!touched.vendorSku && !!errors.vendorSku}
                                    helperText={touched.vendorSku && errors.vendorSku}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    tooltip="If product is available then scan barcode"
                                    type="text"
                                    label="Barcode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.barcode}
                                    name="barcode"
                                    error={!!touched.barcode && !!errors.barcode}
                                    helperText={touched.barcode && errors.barcode}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Product Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={!!touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="UPC"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.upc}
                                    name="state"
                                    error={!!touched.upc && !!errors.upc}
                                    helperText={touched.upc && errors.upc}
                                    sx={{
                                        gridColumn: "span 2"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="EAN"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ean}
                                    name="ean"
                                    error={!!touched.ean && !!errors.ean}
                                    helperText={touched.ean && errors.ean}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <TextFields
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Manufacturer"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.manufacturer}
                                    name="manufacturer"
                                    error={!!touched.manufacturer && !!errors.manufacturer}
                                    helperText={touched.manufacturer && errors.manufacturer}
                                    sx={{
                                        gridColumn: "span 4"
                                    }}
                                />
                                <Box display="grid" width="100%" gap="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gridColumn="span 4">
                                    <Typography sx={{ gridColumn: "span 4" }}> <u>Product Size</u></Typography>
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Height"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.height}
                                        name="height"
                                        error={!!touched.height && !!errors.height}
                                        helperText={touched.height && errors.height}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Width"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.width}
                                        name="width"
                                        error={!!touched.width && !!errors.width}
                                        helperText={touched.width && errors.width}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Length"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.length}
                                        name="length"
                                        error={!!touched.length && !!errors.length}
                                        helperText={touched.length && errors.length}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Weight"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.weight}
                                        name="weight"
                                        error={!!touched.weight && !!errors.weight}
                                        helperText={touched.weight && errors.weight}
                                        sx={{
                                            gridColumn: "span 2"
                                        }}
                                    />
                                    <Typography sx={{ gridColumn: "span 4" }}> <u>Description</u></Typography>
                                    <TextFields
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.description}
                                        name="description"
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                        sx={{
                                            gridColumn: "span 4"
                                        }}
                                    />
                                </Box>

                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type="submit" onSubmit={handleAddItem} color="secondary" variant="outlined" >
                                        Add New SKU
                                    </Button>
                                </Box>

                            </Box>
                        </form>
                    )}
                </Formik>

            </Box>
        </Box>
    )
}
export default AddSkuForm